import React from 'react'
import styled from 'styled-components/macro'

import Row from './row'

import TwitterLogoWhite from '../assets/svg/twitter_w.svg';
import DiscordLogoWhite from '../assets/svg/discord_w.svg';

const FooterFrame = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  min-height: 50px;
  display: grid;
  grid-template-columns: 260px 1fr 120px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 1rem;
  z-index: 21;

  background: #11222E;
  border-color: #ffffff;
  border-style: solid none none none;
  border-width: 2px;
  // Neumorphic
  //box-shadow: inset 8px 8px 16px rgba(0, 0, 0, 0.4),
  //  inset -8px -8px 16px rgba(73, 73, 73, 0.4);
`

const FooterLinks = styled(Row)`
  justify-self: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
`

const StyledFooterLink = styled.a`
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  padding: 8px 12px;
  margin-right: 12px;
  :hover {
    cursor: pointer;
  }
`

const FooterIcons = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
`

const FooterIcon = styled.a`
  padding-right: 16px;
  transition: transform 0.3s ease;
  pointer-events: auto;
  :hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`

export default function Footer() {

    return (
        <FooterFrame>
            <FooterLinks>
                <StyledFooterLink
                href={'https://aloe.capital'}>
                    Main&nbsp;Site
                </StyledFooterLink>
                <StyledFooterLink
                    href={'https://docs.aloe.capital'}
                    target="_blank" rel="noopener noreferrer">
                    Docs
                </StyledFooterLink>
                <StyledFooterLink
                    href={'/terms.pdf'}
                    target="_blank" rel="noopener noreferrer">
                    Terms
                </StyledFooterLink>
                {/*<StyledFooterLink href={'https://garden.aloe.capital'}*/}
                {/*                  target="_blank" rel="noopener noreferrer">*/}
                {/*    Garden!*/}
                {/*</StyledFooterLink>*/}
            </FooterLinks>
            <div> </div>
            <FooterIcons>
                <FooterIcon href={'https://twitter.com/AloeCapital'} target="_blank" rel="noopener noreferrer">
                    <img width={'28px'} src={TwitterLogoWhite} alt={'Twitter'} />
                </FooterIcon>
                <FooterIcon href={'https://discord.gg/gpt4sUv6sw'} target="_blank" rel="noopener noreferrer">
                    <img width={'28px'} src={DiscordLogoWhite} alt={'Discord'} />
                </FooterIcon>
            </FooterIcons>
        </FooterFrame>
    );
}