import React from 'react'
// import {useWeb3React} from "@web3-react/core";
import {ColumnLayout, SingleColumn, SplitColumnHolder} from "../components/common/SplitColumn";
import styled from "styled-components/macro";
import {Wrap, WrapItem} from "@chakra-ui/react";

import {GetAllVaults, VaultType} from "../constants/VaultData";
import VaultCard from "../components/vaultselect/VaultCard";


const Heading = styled.h1`
  display: block;
  font-size: 22pt;
  font-weight: bold;
  color: white;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  text-align: left;
`

const DividingLine = styled.div`
  border-bottom: 1px solid white;
  margin-bottom: 20px;
`

const WrapMargin = styled.div`
  margin: 0 20px;
`


export default function VaultSelect(props: {vaultTypeFilter?: (arg0: VaultType) => boolean}) {

    // const { active } = useWeb3React();

    return (
        <SplitColumnHolder>
            <SingleColumn>
                <ColumnLayout>
                    <DividingLine>
                        <Heading>Select&nbsp;Vault</Heading>
                    </DividingLine>
                    <WrapMargin>
                        <Wrap spacing="14px" justify="space-between">
                            {GetAllVaults().filter((vault) => {
                                if (props.vaultTypeFilter) {
                                    return props.vaultTypeFilter(vault.vaultType);
                                }
                                return true;
                            }).map((vaultData, idx) =>
                                (
                                    <WrapItem key={idx}>
                                        <VaultCard vaultData={vaultData} />
                                    </WrapItem>
                                )
                            )}
                        </Wrap>
                    </WrapMargin>
                </ColumnLayout>
            </SingleColumn>
        </SplitColumnHolder>
    );
}