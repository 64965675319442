import React from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import styled from "styled-components/macro";
import {
    // useDisclosure,
    // Modal,
    // ModalOverlay,
    // ModalContent,
    // Button,
    // Link,
    Text,
    // Spinner,
    useToast,
} from "@chakra-ui/react";

// @ts-ignore
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import {InjectedConnector, NoEthereumProviderError, UserRejectedRequestError } from "@web3-react/injected-connector";
import MetamaskLogo from "../assets/svg/metamask-fox.svg";
// import AloeBalance from "./AloeBalance";

const AccountButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 4px;
`

const ConnectButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 6px 18px;
  color: white;
  font-size: 15pt;
  background: #393c42;
  border-radius: 50px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2),
    -2px -2px 4px rgba(255, 255, 255, 0.2);

  :active {
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1),
      inset -2px -2px 4px rgba(255, 255, 255, 0.2);
  }

  :hover:not(:active) {
    background: linear-gradient(to top left, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)), #393c42;
  }
`

export default function AccountButton (){
    const { active, account, activate, deactivate } = useWeb3React();
    const toast = useToast();

    const OnWeb3ReactError = (error: Error) => {
        const isNoEthereumProviderError = error instanceof NoEthereumProviderError
        if (isNoEthereumProviderError) {
            toast({
                title: "No Ethereum Provider",
                status: "error",
                duration: 9000,
                position: 'bottom',
                isClosable: true,
            })
        }
        const isUserRejectedRequestError = error instanceof UserRejectedRequestError
        if (isUserRejectedRequestError) {
            toast({
                title: "User Rejected Request",
                status: "error",
                duration: 9000,
                position: 'bottom',
                isClosable: true,
            })
        }
        const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError;
        if (isUnsupportedChainIdError) {
            toast({
                title: "Change your network to mainnet.",
                status: "error",
                duration: 9000,
                position: 'bottom',
                isClosable: true,
            })
            deactivate();
        }
    }

    const ConnectAccount = () => {
        const injected = new InjectedConnector({
            supportedChainIds: [1],
        });
        activate(injected, OnWeb3ReactError);
    }

    const DisconnectAccount = () => {
        deactivate();
    }

    return (
        <AccountButtonRow>
            {/*{active && <>*/}
            {/*    <AloeBalance />*/}
            {/*</>}*/}
            <ConnectButton onClick={(active) ? DisconnectAccount : ConnectAccount}>
                    {(active) ? (<>
                            <Jazzicon diameter={23} seed={jsNumberForAddress(account)} />
                            <Text fontWeight="semibold">
                                {account?.substring(0, 6)}
                            </Text>
                        </>)
                        : (<>
                            <img width={'24px'} src={MetamaskLogo} alt="" />
                            <Text>Connect&nbsp;Wallet</Text>
                        </>)}


            </ConnectButton>
        </AccountButtonRow>

    );
}