import React, {useEffect, useState} from 'react';
import styled, {keyframes} from "styled-components/macro";
import {useWeb3React} from "@web3-react/core";
import {Select, Table, Tooltip} from "@chakra-ui/react";
import {MdInfo as InfoIcon, MdInfoOutline as InfoIconOutline, MdSwapHoriz as SwapIcon} from "react-icons/md";
import {VaultData, VaultType} from "../../../constants/VaultData";
import axios from "axios";
import {BigNumber, Contract, ethers} from "ethers";
import BlendPoolAbi from "../../../abis/blend_pool_abi.json";
import {toFixed} from "../../../util/ToFixed";
import {USDC_ADDRESS, WETH_ADDRESS} from "../../../constants/Addresses";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
  font-size: 14pt;
  margin-top: 24px;
  // margin-bottom: 160px; // Padding for the bottom edge of page

  // Neumorphic
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4),
    -8px -8px 16px rgba(73, 73, 73, 0.4);
  overflow: hidden;
`

const TableContainer = styled.div`
  padding: 10px 8px 8px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  thead {
    font-size: 18pt;
  }

  tr {
    padding: 4px;
    border-bottom: 1px solid #5b5b5b;
  }

  tr:last-child {
    border-bottom: none;
  }
`

const HighlightingTr = styled.tr`
  :hover {
    background: rgba(255, 255, 255, 0.2);
  }
`

const ImprovementContainer = styled.div<{open: boolean}>`
  width: 100%;
  //padding: 0 8px 10px;
  padding: ${({open}) => (open) ? '0 8px 10px' : '0 10px'};
  text-transform: uppercase;
  font-size: 12pt;
  font-weight: bold;
  color: #2f3135;
  background: #e5d075; /* fallback for old browsers */
  //background: -webkit-linear-gradient(to left, #ffffff, #e5d075);  /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to left, #ffffff, #e5d075); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: ${({open}) => (open) ? '100%' : '0'};
  transition: 1.0s ease;
`

const ImprovementRow = styled.div`
  padding-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const HeadingSpacer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const HeadingInfoAligner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const SelectAndSwap = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 7fr 1fr;
`

const SelectCentering = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  padding-bottom: 14px;
  color: #e5d075;
`

const colorPulse = keyframes`
  0% { color: white; }
  50% { color: cornflowerblue; }
  100% { color: white; }
`

const PerformanceSwapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  
  transition: 0.3s ease;
  
  // -webkit-animation-name: ${colorPulse};
  // -webkit-animation-iteration-count: infinite;
  // -webkit-animation-duration: 5s;
  // -webkit-animation-timing-function: ease-in-out;
  // -webkit-animation-play-state: running;

  :hover {
    //-webkit-animation-play-state: paused;
    
  }
`

const SwapButtonOutline = styled.div`
  outline: 2px solid white;
  border-radius: 8px;

  transition: 0.3s ease;
  
  :hover {
    color: limegreen;
    outline: 2px solid limegreen;
  }
`

// const SelectUnderline = styled.div`
//   box-sizing: border-box;
//   border-bottom: 1px solid white;
// `

const TableIconWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
`

// const TrImprovementStyle = styled.td`
//   background: #64BD97;
// `

const Heading = styled.div`
  font-size: 18pt;
  font-weight: bold;
`

const TRHeader = styled.tr`
  font-size: 12pt;
  color: lightgray;
  font-weight: bold;
  //text-decoration: underline;
  user-select: none;
  
  :hover {
    
  }
`

const TdRight = styled.td`
  white-space: pre-wrap;
  text-align: right;
`

const Bold = styled.span`
  font-size: 14pt;
  font-weight: bold;
`

// const AloeGreenRecolor = styled.span`
//   color: #64BD97;
// `

const HoverSx = {
    fontSize: '12pt',
}

// const SelectStyling = {
//     color: '#ffffff',
//     // border
// }

enum BasisToken {
    TOKEN0 = 0,
    TOKEN1 = 1,
    USD = 2,
}

type RebalanceSnapshot = {
    inventory0: BigNumber,
    inventory1: BigNumber,
    totalShares: BigNumber,
    tickTWAP: number,
    timestamp: number, // In seconds since Unix epoch
    ethUsd?: BigNumber,
}

type Performance = {
    rawRatio: number,
    percentChangeSinceLastRebalance: number,
    extrapolatedAPY: number,
}

export default function ApyEstimator(props: {vaultData: VaultData}) {

    const { active, account, library } = useWeb3React();

    const [initialized, setInitialized] = useState(false);
    const [perfThisVault, setPerfThisVault] = useState([0,0]); // APY and scale factor
    const [perfUniV2, setPerfUniV2] = useState([0,0]); // APY and Scale Factor
    const [perf5050, setPerf5050] = useState(0); // Just APY
    const [performanceTab, setPerformanceTab] = useState(0);

    const pairIncludesWeth = ((props.vaultData.token0Address === WETH_ADDRESS) || (props.vaultData.token1Address === WETH_ADDRESS))
        && ((props.vaultData.token0Address !== USDC_ADDRESS));
    const [basisToken, setBasisToken] = useState(pairIncludesWeth ? BasisToken.USD : BasisToken.TOKEN0);

    const handleSelectChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        // console.log('Event target value');
        // console.log(event.target.value);
        setBasisToken(Number(event.target.value));
    }

    const formatPercent = (input: number): string => {
        return `${toFixed((input * 100).toString(), 2)}%`;
    }

    const formatMultiplier = (input: number): string => {
        return `${toFixed(input.toString(), 2)}×`;
    }

    const toggleSelectedTab = () => {
        setPerformanceTab((performanceTab === 0) ? 1 : 0);
    }

    useEffect(() => {

        const GetLastRebalanceEvent = async (): Promise<RebalanceSnapshot> => {
            const resp = await axios.get<any>(`https://api.etherscan.io/api?module=logs&action=getLogs&fromBlock=13127003&address=0x8Bc7C34009965ccb8c0C2eB3d4db5a231eCc856C&topic0=0xa8c85a85c54ed78da1a105a8594f070779c6eeae142b1264f39d1bbc3213cbcf&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`);
            const latestRebalance = resp.data.result.reduce((prev: any, curr: any) => {
                if (prev.blockNumber === curr.blockNumber) {
                    return (Number(prev.logIndex) > Number(curr.logIndex)) ? prev : curr;
                }
                return (Number(prev.blockNumber) > Number(curr.blockNumber)) ? prev : curr;
            });

            // console.log('Latest rebalance: ');
            // console.log(latestRebalance);

            const rebalanceData = ethers.utils.defaultAbiCoder.decode(['int24' /*lower*/, 'int24' /*upper*/, 'uint96' /*magic*/, 'uint16' /*urgency*/, 'uint256' /*shares*/, 'uint256' /*inventory0*/, 'uint256' /*inventory1*/], latestRebalance.data);

            // Tick TWAP is at center of range from lower and upper
            const tickTWAP = (rebalanceData[0] + rebalanceData[1]) / 2;

            let ethUsd: BigNumber|undefined = undefined;
            if (basisToken === BasisToken.USD) {
                // const timestamp = Number(latestRebalance.timeStamp); // Etherscan sends timestamp in hex
                // const ftxHistoricalEthPriceResp = await axios.get<any>(`https://ftx.us/api/markets/ETH/USD/candles?resolution=300&start_time=${timestamp.toString(10)}&end_time=${(timestamp + 300).toString(10)}`);
                // const firstCandle = ftxHistoricalEthPriceResp.data.result[0];
                // ethUsd = BigNumber.from(
                //     ((Number(firstCandle.high) + Number(firstCandle.low)) / 2 * 1e6).toFixed(0)
                // );
                ethUsd = BigNumber.from(2886050000);
            }

            return {
                inventory0: rebalanceData[5],
                inventory1: rebalanceData[6],
                totalShares: rebalanceData[4],
                tickTWAP,
                timestamp: Number(latestRebalance.timeStamp),
                ethUsd,
            };
        }

        const GetLatestSnapshot = async (): Promise<RebalanceSnapshot> => {
            const signer = library.getSigner();
            const vaultContract = new Contract(props.vaultData.vaultAddress, BlendPoolAbi).connect(signer);
            const results = await Promise.all([vaultContract.getInventory(), vaultContract.totalSupply()]);

            const tickTWAP = (await vaultContract.getNextPositionWidth()).tickTWAP;

            return {
                inventory0: results[0].inventory0,
                inventory1: results[0].inventory1,
                totalShares: results[1],
                tickTWAP,
                timestamp: Math.floor(Date.now() / 1000),
            };

        }

        const CalculatePricePerShare = (snapshot: RebalanceSnapshot, basisToken: BasisToken): BigNumber => {
            const tokens1PerTokens0 = 1.0001 ** snapshot.tickTWAP;
            let pricePerShare: BigNumber;

            let x = 10000000000000;

            switch (basisToken) {
                case BasisToken.TOKEN0:
                    pricePerShare = snapshot.inventory0.mul(x).add(
                        snapshot.inventory1.mul(x).div(tokens1PerTokens0.toFixed(0))
                    ).div(snapshot.totalShares);
                    break;
                case BasisToken.TOKEN1:
                    pricePerShare = snapshot.inventory1.add(
                        snapshot.inventory0.mul(tokens1PerTokens0.toFixed(0))
                    ).mul(x).div(snapshot.totalShares);
                    break;
                case BasisToken.USD:
                    x = 10;
                    if (props.vaultData.token0Address === WETH_ADDRESS) {
                        // same code as case BasisToken.TOKEN0, but with mul(ethUsd)
                        pricePerShare = snapshot.inventory0.mul(x).add(
                            snapshot.inventory1.mul(x).div(tokens1PerTokens0.toFixed(0))
                        ).mul(snapshot.ethUsd!).div(snapshot.totalShares).div(1e5);
                    } else {
                        // same code as case BasisToken.TOKEN1, but with mul(ethUsd)
                        pricePerShare = snapshot.inventory1.add(
                            snapshot.inventory0.mul(tokens1PerTokens0.toFixed(0))
                        ).mul(snapshot.ethUsd!).div(snapshot.totalShares).div(1e5);
                    }
            }

            return pricePerShare;
        }

        const CalculatePerformanceThisVault = (start: RebalanceSnapshot, end: RebalanceSnapshot, basisToken: BasisToken): Performance => {

            const pricePerShareStart = CalculatePricePerShare(start, basisToken);
            const pricePerShareEnd = CalculatePricePerShare(end, basisToken);

            const ratio = pricePerShareEnd.mul(10000).div(pricePerShareStart).toNumber() / 10000;

            return {
                rawRatio: ratio,
                percentChangeSinceLastRebalance: ratio - 1.0,
                extrapolatedAPY: ratio ** (31536000 / (end.timestamp - start.timestamp)) - 1.0,
            }
        }

        const CalculatePerformanceUniV2 = (start: RebalanceSnapshot, end: RebalanceSnapshot, basisToken: BasisToken): Performance => {
            const denominateInAsset0 = basisToken === BasisToken.TOKEN0 ||
                (basisToken === BasisToken.USD && props.vaultData.token0Address === WETH_ADDRESS);
            let priceRatio = denominateInAsset0 ? 1.0001 ** (end.tickTWAP - start.tickTWAP) : 1.0001 ** (start.tickTWAP - end.tickTWAP);
            if (basisToken === BasisToken.USD) priceRatio *= end.ethUsd!.toNumber() / start.ethUsd!.toNumber();

            const ratio = Math.sqrt(priceRatio);

            return {
                rawRatio: ratio,
                percentChangeSinceLastRebalance: ratio - 1.0,
                extrapolatedAPY: ratio ** (31536000 / (end.timestamp - start.timestamp)) - 1.0,
            }
        }

        const CalculatePerformance5050 = (start: RebalanceSnapshot, end: RebalanceSnapshot, basisToken: BasisToken): Performance => {
            const denominateInAsset0 = basisToken === BasisToken.TOKEN0 ||
                (basisToken === BasisToken.USD && props.vaultData.token0Address === WETH_ADDRESS);
            let priceRatio = denominateInAsset0 ? 1.0001 ** (end.tickTWAP - start.tickTWAP) : 1.0001 ** (start.tickTWAP - end.tickTWAP);
            if (basisToken === BasisToken.USD) priceRatio *= end.ethUsd!.toNumber() / start.ethUsd!.toNumber();

            const ratio = (priceRatio + 1.0) / 2.0;

            return {
                rawRatio: ratio,
                percentChangeSinceLastRebalance: ratio - 1.0,
                extrapolatedAPY: ratio ** (31536000 / (end.timestamp - start.timestamp)) - 1.0,
            }
        }

        const LoadEstimator = async () => {
            if (active) {
                try {
                    // Don't have enough data to load BLEND_V1 APY, as total share count is not emitted
                    if (props.vaultData.vaultType === VaultType.BLEND_V2) {

                        const lastRebalance = await GetLastRebalanceEvent();
                        const currentStatus = await GetLatestSnapshot()

                        if (pairIncludesWeth) {
                            const resp = await axios.get<any>(`https://api.etherscan.io/api?module=stats&action=ethprice&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`);
                            currentStatus.ethUsd = ethers.utils.parseUnits(resp.data.result.ethusd, 6);
                        }

                        const calcThisVault = CalculatePerformanceThisVault(lastRebalance, currentStatus, basisToken);
                        const calcUniV2 = CalculatePerformanceUniV2(lastRebalance, currentStatus, basisToken);
                        const calc5050 = CalculatePerformance5050(lastRebalance, currentStatus, basisToken);

                        const outperformanceVault = calcThisVault.rawRatio / calc5050.rawRatio;
                        const outperformanceUniV2 = calcUniV2.rawRatio / calc5050.rawRatio;

                        setPerfThisVault([calcThisVault.extrapolatedAPY, outperformanceVault]);
                        setPerfUniV2([calcUniV2.extrapolatedAPY, outperformanceUniV2]);
                        setPerf5050(calc5050.extrapolatedAPY);
                        setInitialized(true);

                    } else if (props.vaultData.vaultType === VaultType.BLEND_V1) {
                        const lastRebalance: RebalanceSnapshot = {
                            inventory0: BigNumber.from('7271605309'),
                            inventory1: BigNumber.from('2275359743921746563'),
                            totalShares: BigNumber.from('2300154292715915352'),
                            tickTWAP: 193035,
                            timestamp: 1635161768, // In seconds since Unix epoch
                            ethUsd: BigNumber.from('4220271585'),
                        }
                        const currentStatus = await GetLatestSnapshot()

                        const calcThisVault = CalculatePerformanceThisVault(lastRebalance, currentStatus, basisToken);
                        const calcUniV2 = CalculatePerformanceUniV2(lastRebalance, currentStatus, basisToken);
                        const calc5050 = CalculatePerformance5050(lastRebalance, currentStatus, basisToken);

                        const outperformanceVault = calcThisVault.rawRatio / calc5050.rawRatio;
                        const outperformanceUniV2 = calcUniV2.rawRatio / calc5050.rawRatio;

                        setPerfThisVault([calcThisVault.extrapolatedAPY, outperformanceVault]);
                        setPerfUniV2([calcUniV2.extrapolatedAPY, outperformanceUniV2]);
                        setPerf5050(calc5050.extrapolatedAPY);
                        setInitialized(true);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        };
        LoadEstimator();
    }, [active, account, library, props.vaultData, basisToken, pairIncludesWeth]);

    const aloeImprovement: number = perfThisVault[1] / perfUniV2[1];

    return (
        <>
            <Container>
                <TableContainer>
                    <HeadingSpacer>
                        <HeadingInfoAligner>
                            <Heading>
                                Performance&nbsp;
                            </Heading>
                            <Tooltip label={'Estimates made with data acquired since last rebalance. Past performance not indicative of future outcomes. DYOR.'} placement="right" closeOnClick={false} hasArrow sx={HoverSx}>
                                <div>
                                    <InfoIcon color={'white'} fontSize={'18pt'} />
                                </div>
                            </Tooltip>
                        </HeadingInfoAligner>
                    </HeadingSpacer>
                    <SelectAndSwap>
                        <SelectCentering>
                            💰 denominated&nbsp;in&nbsp;

                            <Select width="30%" size={"md"} variant={"unstyled"} onChange={handleSelectChange} defaultValue={(pairIncludesWeth) ? 2 : 0}>
                                <option value={0}>{props.vaultData.token0Ticker}</option>
                                <option value={1}>{props.vaultData.token1Ticker}</option>
                                {(pairIncludesWeth) ?
                                    <option value={2}>USD</option> : <></>}
                            </Select>
                        </SelectCentering>
                        <PerformanceSwapContainer onClick={toggleSelectedTab}>
                            <SwapButtonOutline>
                                <SwapIcon fontSize={'24pt'} />
                            </SwapButtonOutline>
                        </PerformanceSwapContainer>
                    </SelectAndSwap>
                    <Table>
                        <thead>
                        <TRHeader>
                            <td>strategy</td>
                            <TdRight>{(performanceTab === 0) ? <>relative&nbsp;growth&nbsp;rate</> : <>CAGR</>}</TdRight>
                        </TRHeader>
                        </thead>
                        <tbody>
                        <HighlightingTr key={1}>
                            <td key={1}>This&nbsp;vault&nbsp;
                                <Tooltip label={'Accounts for earned interest & fees, as well as rebalance costs. Higher growth rates are better.'} placement="right" closeOnClick={false} hasArrow sx={HoverSx}>
                                    <TableIconWrapper>
                                        <InfoIconOutline color={'white'} fontSize={'14pt'} />
                                    </TableIconWrapper>
                                </Tooltip>
                            </td>
                            <TdRight key={2}><Bold>{initialized ? (
                                    (performanceTab === 0)
                                        ? formatMultiplier(perfThisVault[1]) : formatPercent(perfThisVault[0]))
                                : `--${(performanceTab === 0) ? '×' : '%'}`}</Bold></TdRight>
                        </HighlightingTr>
                        <HighlightingTr key={2}>
                            <td key={1}>Uniswap&nbsp;V2&nbsp;
                                <Tooltip label={'Doesn\'t include earned fees. Consider this a measure of asset growth + IL.'} placement="right" closeOnClick={false} hasArrow sx={HoverSx}>
                                    <TableIconWrapper>
                                        <InfoIconOutline color={'white'} fontSize={'14pt'} />
                                    </TableIconWrapper>
                                </Tooltip>
                            </td>
                            <TdRight key={2}><Bold>{(initialized) ? '≈' : ''}{initialized ? (
                                    (performanceTab === 0) ?
                                formatMultiplier(perfUniV2[1]) : formatPercent(perfUniV2[0])
                            ) : `--${(performanceTab === 0) ? '×' : '%'}`}</Bold></TdRight>
                        </HighlightingTr>
                        <HighlightingTr key={3}>
                            <td key={1}>50/50&nbsp;HODL&nbsp;
                                <Tooltip label={'A simple strategy where your portfolio consists of equal amounts of each asset. Used as baseline for this comparison.'} placement="right" closeOnClick={false} hasArrow sx={HoverSx}>
                                    <TableIconWrapper>
                                        <InfoIconOutline color={'white'} fontSize={'14pt'} />
                                    </TableIconWrapper>
                                </Tooltip>
                            </td>
                            <TdRight key={2}>{initialized ? (
                                    (performanceTab === 0) ?
                                        <>
                                            <i>
                                                baseline&nbsp;
                                            </i>
                                            <Bold>1.00×</Bold>
                                        </>
                                        : <Bold>
                                            {formatPercent(perf5050)}
                                        </Bold>)
                                : <Bold>
                                --
                                    {(performanceTab === 0) ? '×' : '%'}
                                </Bold>
                                }</TdRight>
                        </HighlightingTr>
                        </tbody>
                    </Table>
                </TableContainer>
                <ImprovementContainer open={initialized}>
                    <ImprovementRow>
                        Growing ≈{initialized ? formatMultiplier(aloeImprovement) : '--'} faster than Uniswap v2
                    </ImprovementRow>
                </ImprovementContainer>
            </Container>
        </>
    );
}