
export function TickerToColor(ticker: string) {
    switch (ticker) {
        case 'USDC':
            return '#2775CA';
        case 'WETH':
            return '#c8ffff';
        case 'OHM':
            return '#efc580';
        default:
            return '#ffffff';
    }

}