import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import Row, { RowFixed } from "./row"
import Logo from '../assets/svg/aloe_capital_logo_no_border.svg'
import AccountButton from "./AccountButton";
import AuditBanner from "./AuditBanner";


const MenuItems = [
    {
        title: 'Blend',
        name: 'blend',
        url: '/blendvaults',
    },
    {
        title: 'Active',
        name: 'active',
        url: '/activevaults',
    },
    // {
    //     title: 'Stake',
    //     name: 'stake',
    //     url: '/stake',
    // },
    // {
    //     title: 'Claim',
    //     name: 'claim',
    //     url: '/claim',
    // },
    {
        title: 'Vote',
        name: 'vote',
        url: '/vote',
    },
]

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr 200px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  top: 0;
  position: relative;
  padding: 1rem;
  z-index: 21;
`

// const HeaderElementWrap = styled.div`
//   display: flex;
//   align-items: center;
// `

const HeaderRow = styled(RowFixed)`
   width: 100%;
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  width: fit-content;
  padding: 4px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  :hover {
    cursor: pointer;
  }
`

const AloeIcon = styled.div`
  padding-left: 8px;
  transition: transform 0.3s ease;
  :hover {
    transform: scale(1.1);
  }
`

const TitleSpan = styled.span`
  color: white;
  font-size: 18pt;
  user-select: none;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
    activeClassName,
})`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-size: 1.3rem;
  width: fit-content;
  font-weight: 500;
  padding: 8px 12px;
  user-select: none;

  &.${activeClassName} {
    background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(74, 198, 207, 0.21) 100%);
    font-weight: 600;
    border-bottom: 5px solid #4ED993;
  }
`

const BetaSpan = styled.span`
  color: lightslategray;
  font-size: 12pt;
  user-select: none;
  padding-left: 6px;
`

const TitleAlignmentDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`

export default function Header() {

    return (<HeaderContainer>
      <AuditBanner/>
      <HeaderFrame>
          <HeaderRow>
              <Title href=".">
                  <AloeIcon>
                      <img width={'56px'} src={Logo} alt="logo" />
                  </AloeIcon>
              </Title>
              <TitleAlignmentDiv>
                  <TitleSpan>
                      Aloe&nbsp;Capital
                  </TitleSpan>
                  <BetaSpan>
                      &nbsp;BETA
                  </BetaSpan>
              </TitleAlignmentDiv>
          </HeaderRow>
          <HeaderLinks>
              {MenuItems.map((menuitem => (
                  <StyledNavLink
                      id={`${menuitem.name}-nav-link`}
                      to={menuitem.url}
                      key={menuitem.name}
                  >
                      {menuitem.title}
                  </StyledNavLink>
              )))}
          </HeaderLinks>
          <AccountButton />
      </HeaderFrame>
    </HeaderContainer>);

}