// export const BETA_ALOE_ADDRESS = '0xE94aBe07C46c9cE182c85b6B875Fdd1ac5541b83';
// export const FAUCET_ADDRESS = '0xE94aBe07C46c9cE182c85b6B875Fdd1ac5541b83';

export const PRE_ALOE_ADDRESS = '0xa10Ee8A7bFA188E762a7bc7169512222a621Fab4';
export const MERKLE_DISTRIBUTOR_ADDRESS = '0x363c421901B7BDCa0f2a17dA03948D676bE350E4';

export const USDC_WETH_PREDICTIONS_ADDRESS = '0x263C5BDFe39c48aDdE8362DC0Ec2BbD770A09c3a';
export const USDC_WETH_ACTIVE_VAULT_ADDRESS = '0xf5F30EaF55Fd9fFc70651b13b791410aAd663846';
export const USDC_WETH_BLEND_VAULT_ADDRESS = '0x1cF3e6f18223a1f2A445f2cD60538Af380e98074';

export const USDC_ADDRESS = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48';
export const WETH_ADDRESS = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';


