import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {Tooltip} from "@chakra-ui/react";
import {Contract, ethers} from "ethers";
import BlendPoolAbi from "../../../abis/blend_pool_abi.json";
import ERC20Abi from "../../../abis/erc20_abi.json"
import {toFixed} from "../../../util/ToFixed";
import axios from "axios";
import {useWeb3React} from "@web3-react/core";
import {VaultData, VaultType} from "../../../constants/VaultData";
import {CompoundResolver} from "../../../silo/CompoundResolver";
import {GetSiloData, SiloType} from "../../../constants/SiloData";
import {String10E} from "../../../util/Decimals";
import {TickerToColor} from "../../common/TickerToColor";
import {FuseResolver} from "../../../silo/FuseResolver";
import {StakeOhmResolver} from "../../../silo/StakeOhmResolver";

const Wrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 160px; // Padding for the bottom edge of page
  width: 100%;
  padding: 8px;

  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4),
    -8px -8px 16px rgba(73, 73, 73, 0.4);
  
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-size: 18pt;
  font-weight: bold;
  
`

const TokenLabelPair = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const TokenLabel = styled.div`
  color: white;
  font-size: 14pt;
`

const BarBg = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  width: 100%;
  height: 36px;
  border-radius: 400px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const BarFgInactve = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  background: darkgray;
`

const BarFg1 = styled.div<{ percent: number, color: string }>`
  margin: 0;
  background: ${({ color }) => `${color}`};
  height: 100%;
  border-right: 2px #2f3135 solid;
  width: ${({ percent }) => `${percent}%`};
`

const BarUniswap = styled.div<{ percent: number, color1: string, color2: string, }>`
  margin: 0;
  padding: 0;
  //background: linear-gradient(90deg, rgba(39,117,202,1) 0%, rgba(200,255,255,1) 100%;
  background: ${({ color1, color2 }) => `linear-gradient(90deg, ${color1} 0%, ${color2} 100%);`};
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: ${({ percent }) => `${percent}%`};
`

const BarFg2 = styled.div<{ percent: number }>`
  margin: 0;
  height: 100%;
  border-right: 2px #2f3135 solid;
  width: ${({ percent }) => `${percent}%`};
`

const BarFg3 = styled.div<{ percent: number }>`
  margin: 0;
  height: 100%;
  border-right: 2px #2f3135 solid;
  width: ${({ percent }) => `${percent}%`};
`

const BarFg4 = styled.div<{ percent: number, color: string }>`
  margin: 0;
  background: ${({ color }) => `${color}`};
  height: 100%;
  width: ${({ percent }) => `${percent}%`};
`

const HoverSx = {
    fontSize: '12pt',
}


export default function BlendRatioGraph(props: {vaultData: VaultData}) {

    const { active, account, library } = useWeb3React();

    const [initialized, setIntialized] = useState(false);
    // Value, reflected in dollars
    const [token0Silo, setToken0Silo] = useState(1);
    const [token0LP, setToken0LP] = useState(1);
    const [token1LP, setToken1LP] = useState(1);
    const [token1Silo, setToken1Silo] = useState(1);

    // Formatted label numbers
    // const [token0SiloLabel, setToken0SiloLabel] = useState<LabelContents>({usdValue: '', amount: ''});
    // const [token0LPLabel, setToken0LPLabel] = useState<LabelContents>({usdValue: '', amount: ''});
    // const [token1LPLabel, setToken1LPLabel] = useState<LabelContents>({usdValue: '', amount: ''});
    // const [token1SiloLabel, setToken1SiloLabel] = useState<LabelContents>({usdValue: '', amount: ''});

    const GraphLaberGenerator = (ticker: string, siloType: SiloType) => {
        switch (siloType) {
            case SiloType.COMPOUND:
                return `${ticker} in Compound`;
            case SiloType.STAKE_OHM:
                return `${ticker} staked as sOHM`;
            case SiloType.RARI_FUSE:
                return `${ticker} in Rari Fuse`;
            default:
                return '';
        }
    }


    useEffect(() => {
        const LoadBar = async() => {
            if (active) {
                try {
                    const signer = library.getSigner();
                    const vaultContract = new Contract(props.vaultData.vaultAddress, BlendPoolAbi).connect(signer);
                    const token0Contract = new Contract(props.vaultData.token0Address, ERC20Abi).connect(signer);
                    const token1Contract = new Contract(props.vaultData.token1Address, ERC20Abi).connect(signer);
                    const [reserves0, reserves1] = await vaultContract.getInventory();

                    // console.log('reserves0 ' + reserves0);
                    // console.log('reserves1 ' + reserves1);

                    const siloData = GetSiloData(props.vaultData.vaultAddress);
                    if (!siloData) {
                        throw new Error('No Silo data known!');
                    }

                    let maintenanceBudget0 = await vaultContract.maintenanceBudget0();
                    let maintenanceBudget1 = await vaultContract.maintenanceBudget1();

                    let token0ExtraHoldings = (await token0Contract.balanceOf(props.vaultData.vaultAddress)).sub(maintenanceBudget0);
                    let token1ExtraHoldings = (await token1Contract.balanceOf(props.vaultData.vaultAddress)).sub(maintenanceBudget1);

                    if (token0ExtraHoldings.lt(0)) {
                        token0ExtraHoldings = ethers.BigNumber.from(0);
                    }

                    if (token1ExtraHoldings.lt(0)) {
                        token1ExtraHoldings = ethers.BigNumber.from(0);
                    }


                    const resp = await axios.get<any>(`https://api.etherscan.io/api?module=stats&action=ethprice&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`);
                    const ethUsd = ethers.utils.parseUnits(resp.data.result.ethusd, 6);

                    // console.log(ethUsd);

                    if (props.vaultData.vaultType === VaultType.BLEND_V1) {

                        const res0 = await CompoundResolver(props.vaultData, siloData.silo0Address, false, signer, ethers.BigNumber.from('1000000'));
                        const siloToken0Balance = res0.balance;
                        const siloToken0Usd = res0.balanceUSD;

                        const res1 = await CompoundResolver(props.vaultData, siloData.silo1Address, true, signer, ethUsd);
                        const siloToken1Balance = res1.balance;
                        const siloToken1Usd = res1.balanceUSD;

                        const token0LPBalance = reserves0.sub(siloToken0Balance).sub(token0ExtraHoldings);
                        const token1LPBalance = reserves1.sub(siloToken1Balance).sub(token1ExtraHoldings);

                        const token0LPUsd = token0LPBalance;
                        const token1LPUsd = token1LPBalance.mul(ethUsd).div(String10E(18));

                        // console.log('uToken0Balance ' + uToken0Balance);
                        // console.log('token0LPBalance ' + token0LPBalance);
                        // console.log('token1LPValue ' + token1LPValue);
                        // console.log('uToken1Value ' + uToken1Value);

                        setToken0Silo((siloToken0Usd).toNumber());
                        setToken0LP((token0LPUsd).toNumber());
                        setToken1Silo((siloToken1Usd).toNumber());
                        setToken1LP((token1LPUsd).toNumber());
                    } else {

                        const tickTWAP = (await vaultContract.getNextPositionWidth()).tickTWAP;
                        const TWAP = (1.0001 ** tickTWAP).toFixed(0);
                        const decimalDelta = props.vaultData.token1Decimals - props.vaultData.token0Decimals;
                        const token0Pertoken1 = (ethers.BigNumber.from(String10E(decimalDelta + 6)).div(TWAP));
                        // OHM per ETH, with 6 decimal place

                        const ohmPrice = ethUsd.mul(String10E(6)).div(token0Pertoken1);

                        const res0 = await StakeOhmResolver(props.vaultData, siloData.silo0Address, false, signer, ohmPrice);
                        const siloToken0Balance = res0.balance;
                        const siloToken0Usd = res0.balanceUSD;

                        const res1 = await FuseResolver(props.vaultData, siloData.silo1Address, true, signer, ethUsd);
                        const siloToken1Balance = res1.balance;
                        const siloToken1Usd = res1.balanceUSD;

                        const token0LPBalance = reserves0.sub(siloToken0Balance).sub(token0ExtraHoldings);
                        const token1LPBalance = reserves1.sub(siloToken1Balance).sub(token1ExtraHoldings);

                        const token0LPUsd = token0LPBalance.mul(ohmPrice).div(String10E(9));
                        const token1LPUsd = token1LPBalance.mul(ethUsd).div(String10E(18));

                        // console.log('siloToken0Value ' + siloToken0Usd.toString());
                        // console.log('token0LPBalance ' + token0LPBalance);
                        // console.log('siloToken1Value ' + siloToken1Usd.toString());
                        // console.log('token1LPBalance ' + token1LPBalance);
                        // console.log('token1LPValue ' + token1LPValue);
                        // console.log('uToken1Value ' + uToken1Value);

                        setToken0Silo((siloToken0Usd).toNumber());
                        setToken0LP((token0LPUsd).toNumber());
                        setToken1Silo((siloToken1Usd).toNumber());
                        setToken1LP((token1LPUsd).toNumber());
                    }


                    setIntialized(true);

                    // setToken0Silo(0);
                    // setToken0LP(0);
                    // setToken1Silo(0);
                    // setToken1LP(0);
                } catch (e) {
                    console.log(e);
                }
            }
        }
        LoadBar();
    }, [active, account, library, props.vaultData]);

    const total = token0Silo + token0LP + token1LP + token1Silo;
    const Bar1Percent = 100 * token0Silo / total;
    const LPBarsPercent = 100 * (token0LP + token1LP) / total;
    // Inner two bars are normalized against themselves
    const Bar2Percent = 100 * token0LP / (token0LP + token1LP);
    const Bar3Percent = 100 * token1LP / (token0LP + token1LP);
    const Bar4Percent = 100 * token1Silo / total;

    let Token0OverallPercentage: number = 100 * (token0Silo + token0LP) / total;
    let Token1OverallPercentage: number = 100 * (token1Silo + token1LP) / total;

    if (isNaN(Token0OverallPercentage)) {
        Token0OverallPercentage = 50
    }

    if (isNaN(Token1OverallPercentage)) {
        Token1OverallPercentage = 50
    }

    const siloData = GetSiloData(props.vaultData.vaultAddress);

    return (
        <Wrapper>
            <Header>Token&nbsp;Allocation</Header>

            <TokenLabelPair>
                <TokenLabel>
                    <b>{props.vaultData.token0Ticker}</b>
                    {(active && initialized) ? (<>
                        &nbsp;-&nbsp;{toFixed(Token0OverallPercentage.toString(), 2)}%
                    </>) : <></>
                    }
                </TokenLabel>
                <TokenLabel>
                    {(active && initialized) ? (<>
                        {toFixed(Token1OverallPercentage.toString(), 2)}%&nbsp;-&nbsp;</>) : <></> }
                    <b>{props.vaultData.token1Ticker}</b>
                </TokenLabel>
            </TokenLabelPair>
            <BarBg>
                {(active && initialized) ? (<>
                    <Tooltip label={`${(siloData) && GraphLaberGenerator(props.vaultData.token0Ticker, siloData.silo0Type)}: $${toFixed((token0Silo / (10 ** 6)).toString(), 2)}`} closeOnClick={false} hasArrow sx={HoverSx}>
                    <BarFg1 percent={Bar1Percent} color={TickerToColor(props.vaultData.token0Ticker)}/>
                </Tooltip>
                    <BarUniswap percent={LPBarsPercent} color1={TickerToColor(props.vaultData.token0Ticker)} color2={TickerToColor(props.vaultData.token1Ticker)}>
                        <Tooltip label={`${props.vaultData.token0Ticker} in Uniswap: $${toFixed((token0LP / (10 ** 6)).toString(), 2)}`} closeOnClick={false} hasArrow sx={HoverSx}>
                            <BarFg2 percent={Bar2Percent} />
                        </Tooltip>
                        <Tooltip label={`${props.vaultData.token1Ticker} in Uniswap: $${toFixed((token1LP / (10 ** 6)).toString(), 2)}`} closeOnClick={false} hasArrow sx={HoverSx}>
                            <BarFg3 percent={Bar3Percent} />
                        </Tooltip>
                    </BarUniswap>
                    <Tooltip label={`${(siloData) && GraphLaberGenerator(props.vaultData.token1Ticker, siloData.silo1Type)}: $${toFixed((token1Silo / (10 ** 6)).toString(), 2)}`} closeOnClick={false} hasArrow sx={HoverSx}>
                        <BarFg4 percent={Bar4Percent} color={TickerToColor(props.vaultData.token1Ticker)}/>
                    </Tooltip>
                </>) : (<BarFgInactve/>)}
            </BarBg>
        </Wrapper>
    );
}