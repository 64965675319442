import React from 'react'
import {SplitColumn, SplitColumnHolder, ColumnLayout} from "../components/common/SplitColumn";
import TokenSelector from "../components/common/TokenSelector";
import VaultInteractionTabs from "../components/vault/VaultInteractionTabs";
import VaultStatsTable from "../components/vault/stats/VaultStatsTable";
// import {USDC_WETH_ACTIVE_VAULT_ADDRESS} from "../constants/Addresses";
import ActiveVaultInfo from "../components/vault/ActiveVaultInfo";
import {VaultData} from "../constants/VaultData";

/*

 body={'Placing funds into an Aloe Vault will allow Aloe to automatically manage Uniswap V3 positions for you.\n' +
                    '\n' +
                    'Position boundaries are controlled by the market sentiment of stakers, which statistically regresses to real trading ranges. Your deposit ' +
                    'must be in the ratio of the vault\'s current holdings, which can vary over time.' +
                    '\n\n'}
 */

export default function ActiveVault(props: {vaultData: VaultData}) {
    return (
        <SplitColumnHolder>
            <SplitColumn>
                <ColumnLayout>
                    <ActiveVaultInfo />
                    <VaultStatsTable vaultData={props.vaultData}/>
                </ColumnLayout>
                <ColumnLayout>
                    <TokenSelector vaultData={props.vaultData}/>
                    <VaultInteractionTabs vaultData={props.vaultData} disabledDeposits />
                </ColumnLayout>
            </SplitColumn>
        </SplitColumnHolder>
    );
}