import React from "react";
import {Body, Heading} from "../common/InfoBox";
import styled from "styled-components/macro";
import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    ModalBody,
    useDisclosure,
    ModalFooter
} from "@chakra-ui/react";
import {MdReportProblem} from "react-icons/md";

const ExtraTextHolder = styled.div`
  display: block;
  font-size: 13pt;
  text-wrap: normal;
  width: 100%;
  color: white;
  letter-spacing: unset;
  padding: 0px;
  white-space: pre-wrap;
  a {
    text-decoration: underline;
  }
  button {
    text-decoration: underline;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  button {
    text-decoration: underline;
    font-weight: bold;
  }
  //background: #e55151;

`

export const InfoContainer = styled.div`
  padding: 6px 10px;
  // margin: 0px 0px 100px 0px; // Padding between info box and bottom edge of page
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  ul {
    margin-left: 24px;
  }

  h2 {
    font-size: 16pt;
    font-weight: bold;
  }

  // Neumorphic
  //border-radius: 16px;
  //box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4),
  //  -8px -8px 16px rgba(73, 73, 73, 0.4);
`

export default function BlendVaultInfo() {

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <InfoContainer>
                <Heading>
                    Aloe&nbsp;Blend&nbsp;Vault
                </Heading>
                <Body>
                    <div>
                        <p>Placing funds into a Blend Vault will allow Aloe to use Uniswap V3 and yield-earning silos on your behalf.</p>
                        <br></br>
                        <p>When you deposit to the vault, your tokens are pooled together with all other users. Once conditions are right, the vault can be "rebalanced" -- some tokens get placed in Uniswap, and some in the token's silo. <b>Blend is designed to allocate tokens such that overall value will be split 50/50 between the two tokens</b>, just like Uniswap V2. In Aloe Blend, you earn yield from both Uniswap V3 and the silos, unlike Uniswap V2.</p>
                        <br></br>
                        <ExtraTextHolder>
                            Convert between ETH and WETH on <a href={'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'} target="_blank" rel="noopener noreferrer">Uniswap</a>.
                            <br></br>
                            <br></br>
                            <Row><MdReportProblem color={'#f5bb48'} display={'inline-block'} fontSize={'24pt'}/>&nbsp;See&nbsp;<button onClick={onOpen}>risks</button>.</Row>
                        </ExtraTextHolder>
                    </div>
                </Body>
            </InfoContainer>
            <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="outside">
                <ModalOverlay />
                <ModalContent color="white" bgColor="#2f3135">
                    <ModalCloseButton colorScheme="blackAlpha"/>
                    <ModalHeader>Aloe Blend Vault Risks</ModalHeader>
                    <ModalBody>
                        <h2>Risks</h2>
                        <p>NOTE: This is not an exhaustive list! Please do your own research before depositing, and never deposit more than you can afford to lose.</p>
                        <br></br><i>Execution Risk</i>
                        <p>This contract is unaudited, and vault performance is dependent on rebalances that must be triggered manually. Under certain conditions, these rebalances are incentivized for the caller. This is not a guarantee of their execution. Aloe Capital is not responsible for rebalancing.</p>
                        <br></br><i>Base Protocol Risk</i>
                        <p>The underlying protocols (Uniswap and silos) present risk from both their code and the potential for deleterious governance action.</p>
                        <br></br><i>Impermanent Loss</i>
                        <p>This vault is subject to the same impermanent loss as a standard Uniswap V2 position. It may also suffer permanent loss if a rebalance occurs after the price has exited its Uniswap position.</p>
                        <br></br><i>Tokens and Base Layer Risk</i>
                        <p>USDC is a centralized stablecoin. It is subject to regulation, particularly in the US. ETH is decentralized, but blockchain clients can break and bugs can be exploited. This interface may not always work.</p>
                    </ModalBody>
                    <ModalFooter />
                </ModalContent>
            </Modal>
        </>

    );
}