import React, {useState} from "react";
import styled from "styled-components/macro";
import {NumberInput, NumberInputField} from "@chakra-ui/react";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from "@chakra-ui/react"
import {useWeb3React} from "@web3-react/core";
import {Contract, ethers} from "ethers";
// import {USDC_WETH_BLEND_VAULT_ADDRESS} from "../../constants/Addresses";
import ActiveVaultAbi from "../../abis/pool_abi.json";
import erc20ABI from "../../abis/erc20_abi.json";
import WithdrawPreview from "./WithdrawPreview";
import BlendVaultAbi from "../../abis/blend_pool_abi.json";
import {IsBlendVault, VaultData} from "../../constants/VaultData";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
`

const TickerLabel = styled.div`
  font-size: 18pt;
  font-weight: bold;
  padding: 6px 6px 6px 8px;
`

const NumberInputSx = {
    border: 'none',
    fontSize: '16pt',
    textAlign: 'right',
    _focus: {outline: 'none'},
    background: '#2f3135',
    borderRadius: '50px',
    padding: '8px',
}

const WithdrawButtonActive = styled.button`
  margin: 0 40px;
  width: 100%;
  padding: 8px 50px;
  font-size: 18pt;
  white-space: nowrap;
  font-weight: bold;

  // Neumorphic
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2),
    -8px -8px 16px rgba(255, 255, 255, 0.2);
  background: linear-gradient(to top left, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.2));

  :active {
    border-radius: 16px;
    box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.1),
      inset -4px -4px 8px rgba(255, 255, 255, 0.2);
    background: none;
  }

  :hover:not(:active) {
    background: linear-gradient(to top left, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.3));
  }
`

const ToggleSx = {
    _focus: {outline: 'none'},
}

const AccordionSx = {
    border: '0',
}

const formatPercent = (input: string) => {
    return `${input}%`;
}

const TextWrapper = styled.div`
  white-space: pre-wrap;
`

const MaxButton = styled.button`
  color: white;
  border: none;
  text-decoration: underline;
  font-size: 12pt;
  padding: 4px;
`

const parsePercent = (val: string): string => val.replace(/^%/, "");

export default function DepositTab(props: {vaultData: VaultData}) {

    const {active, library, account} = useWeb3React();

    const [sharesWithdraw, setSharesWithdraw] = useState('');
    const [withdrawRatioChange, setWithdrawRatioChange] = useState('1.0');

    const Withdraw = () => {
        if (active) {
            try {
                const signer = library.getSigner();
                const poolContract = new Contract(props.vaultData.vaultAddress, (IsBlendVault(props.vaultData.vaultType)) ? BlendVaultAbi : ActiveVaultAbi).connect(signer);
                poolContract.withdraw(ethers.utils.parseUnits(sharesWithdraw, 18), 0, 0);

            } catch (e) {
                console.log(e);
            }
        }
    }

    const SetMax = async () => {
        try {
            const poolContract = new Contract(props.vaultData.vaultAddress, erc20ABI).connect(library);
            const balance: string = await poolContract.balanceOf(account);
            setSharesWithdraw(ethers.utils.formatUnits(balance, 18));
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Container>
            <Row>
                <MaxButton onClick={SetMax}>
                    max
                </MaxButton>
                <NumberInput
                    onChange={(value) => setSharesWithdraw(value)}
                    value={sharesWithdraw}
                    min={0}
                >
                    <NumberInputField placeholder="0.0" sx={NumberInputSx}/>
                </NumberInput>
                <TickerLabel>
                    Shares
                </TickerLabel>
            </Row>
            <Row>
                <WithdrawPreview vaultData={props.vaultData} shares={sharesWithdraw}/>
            </Row>
            <Row>
                <Accordion allowToggle>
                    <AccordionItem sx={AccordionSx}>
                        <AccordionButton sx={ToggleSx}>
                            <AccordionIcon />
                            <Box flex="1" textAlign="center">
                                Maximum Withdrawal Ratio Change
                            </Box>
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <NumberInput
                                onChange={(value) => setWithdrawRatioChange(parsePercent(value))}
                                value={formatPercent(withdrawRatioChange)}
                                min={0}
                            >
                                <NumberInputField placeholder="0.0" sx={NumberInputSx}/>
                            </NumberInput>
                            <TextWrapper>
                                {'Because of price movements, your withdrawal ratio might change.\n' +
                                'Transaction reverts if the total change is greater than this limit for either token.'}
                            </TextWrapper>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Row>
            <Row>
                <WithdrawButtonActive onClick={Withdraw}>
                    Confirm
                </WithdrawButtonActive>
            </Row>
        </Container>
    );

}