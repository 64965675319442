import React from 'react'
import {SplitColumn, SplitColumnHolder, ColumnLayout} from "../components/common/SplitColumn";
import TokenSelector from "../components/common/TokenSelector";
import VaultInteractionTabs from "../components/vault/VaultInteractionTabs";
import VaultStatsTable from "../components/vault/stats/VaultStatsTable";
import BlendRatioGraph from "../components/vault/stats/BlendRatioGraph";
import BlendVaultInfo from "../components/vault/BlendVaultInfo";
import {VaultData} from "../constants/VaultData";
import ApyEstimator from "../components/vault/stats/ApyEstimator";

export default function BlendVault(props: {vaultData: VaultData}) {
    return (
        <SplitColumnHolder>
            <SplitColumn>
                <ColumnLayout>
                    <BlendVaultInfo />
                    <VaultStatsTable vaultData={props.vaultData}/>
                </ColumnLayout>
                <ColumnLayout>
                    <TokenSelector vaultData={props.vaultData} />
                    <VaultInteractionTabs vaultData={props.vaultData} disabledDeposits={true}/>
                    <ApyEstimator vaultData={props.vaultData} />
                    <BlendRatioGraph vaultData={props.vaultData} />
                </ColumnLayout>
            </SplitColumn>
        </SplitColumnHolder>
    );
}