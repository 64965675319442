import React from 'react'
import styled from "styled-components/macro";
import HoloLogo from '../assets/svg/aloe_capital_logo_holo.svg'

const ComingSoonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
`

const Subtitle = styled.div`
  color: #D8D8D8;
  font-size: 22pt;
`

export default function UnrecognizedVault() {
    return (
        <ComingSoonWrapper>
            <img width='40%' src={HoloLogo} alt="logo" />
            <Subtitle>Vault address not recognized.</Subtitle>
        </ComingSoonWrapper>
    );
}