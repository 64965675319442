import React, { useState } from "react";
import styled from "styled-components/macro";
import CloseSVG from "../assets/svg/close_white_24dp.svg";

const BannerContainer = styled.div<{closed: boolean}>`
  width: 100%;
  height: ${({closed}) => (closed) ? '0' : '100%'};
  display: inline-block;
  background: rgba(255, 122, 0, 0.08);
  color: #FF7A00;
  white-space: nowrap;
  overflow-y: hidden;
  //overflow-x: scroll;
  text-align: center;
  outline: none;
  cursor: auto;
  transition: 0.3s ease;
  padding: ${({closed}) => (closed) ? '0' : '2px'};
  margin: 0;
  border: none;

  a {
    text-decoration: underline;
  }
`
const BannerSpan = styled.div`
  background: rgba(255, 122, 0, 0.08);
  background-clip: padding-box;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 1fr 20px;
  grid-template-rows: 1fr;
  padding: 0 5px;
`

const BannerText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const CloseButton = styled.button`
  border: none;
  cursor: pointer;
`

export default function AuditBanner() {

    const [closed, setClosed] = useState(false);

    return (
        <BannerContainer closed={closed}>
            <BannerSpan>
                <BannerText>
                    Note: This is the app for the Aloe Blend Beta. The full interface for permissionless Aloe Blend will launch shortly.
                    {/*See <a href="https://aloe.capital/#faq">risks</a>.*/}
                </BannerText>
                <CloseButton onClick={() => {setClosed(true)}}>
                    <img width={'16dp'} src={CloseSVG} alt="X" />
                </CloseButton>
            </BannerSpan>
        </BannerContainer>
    );
}