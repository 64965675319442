import React from "react";
import {Body, Heading} from "../common/InfoBox";
import styled from "styled-components/macro";
import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    ModalBody,
    useDisclosure,
    ModalFooter
} from "@chakra-ui/react";
import {MdReportProblem} from "react-icons/md";
import {InfoContainer} from "./BlendVaultInfo";

const ExtraTextHolder = styled.div`
  display: block;
  font-size: 13pt;
  text-wrap: normal;
  width: 100%;
  color: white;
  letter-spacing: unset;
  padding: 0px;
  white-space: pre-wrap;
  a {
    text-decoration: underline;
  }
  button {
    text-decoration: underline;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  button {
    text-decoration: underline;
    font-weight: bold;
  }
  //background: #e55151;

`

export default function ActiveVaultInfo() {

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <InfoContainer>
                <Heading>
                    Aloe&nbsp;Active&nbsp;Vault
                </Heading>
                <Body>
                    <div>
                        <p>Placing funds into an Active Vault will allow Aloe and Aloe Strategists to manage Uniswap V3 positions on your behalf.</p>
                        <br></br>
                        <p>When you deposit to the vault, your tokens are pooled together with all other users'. Tokens are placed in Uniswap V3 based on the aggregate sentiment of Aloe Strategists, who can submit price predictions every hour. By using Active, you're trusting this prediction market to manage your funds.</p>
                        <br></br>
                        <ExtraTextHolder>
                            Convert between ETH and WETH on <a href={'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'} target="_blank" rel="noopener noreferrer">Uniswap</a>.
                            <br></br>
                            <br></br>
                            <Row><MdReportProblem color={'#f5bb48'} display={'inline-block'} fontSize={'24pt'}/>&nbsp;See&nbsp;<button onClick={onOpen}>risks</button>.</Row>
                        </ExtraTextHolder>
                    </div>
                </Body>
            </InfoContainer>
            <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="outside">
                <ModalOverlay />
                <ModalContent color="white" bgColor="#2f3135">
                    <ModalCloseButton colorScheme="blackAlpha"/>
                    <ModalHeader>Aloe Active Vault Risks</ModalHeader>
                    <ModalBody>
                        <h2>Risks</h2>
                        <p>NOTE: This is not an exhaustive list! Please do your own research before depositing, and never deposit more than you can afford to lose.</p>
                        <br></br><i>Execution Risk</i>
                        <p>This contract is unaudited, and vault performance is dependent on rebalances, liquidity sniping, stretching, and strategists' predictions. Aloe Capital is not responsible for any of these actions, though we may perform them from time to time. The underlying protocol (Uniswap) may also present some risk.</p>
                        <br></br><i>Impermanent Loss</i>
                        <p>This vault is subject to both impermanent and permanent losses. The predictions market & proactive rebalances were designed to reduce these losses, but this is not a guarantee and the mechanisms are still experimental.</p>
                        <br></br><i>Tokens and Base Layer Risk</i>
                        <p>USDC is a centralized stablecoin. It is subject to regulation, particularly in the US. ETH is decentralized, but blockchain clients can break and bugs can be exploited. This interface may not always work.</p>
                    </ModalBody>
                    <ModalFooter />
                </ModalContent>
            </Modal>
        </>

    );
}