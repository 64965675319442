
export enum SiloType {
    UNKNOWN,
    INVALID,
    COMPOUND,
    AAVE,
    RARI_FUSE,
    STAKE_OHM,
}

export type SiloData = {
    silo0Type: SiloType,
    silo1Type: SiloType,
    // Addresses needed to read Silo data - cToken, fToken, sOHM addr, etc. Contextual meaning with silo type
    silo0Address: string,
    silo1Address: string,
}

const SiloDataMap = new Map<string, SiloData>([
    // Blend Vault V1: USDC/WETH
    ['0x1cF3e6f18223a1f2A445f2cD60538Af380e98074', {
        silo0Type: SiloType.COMPOUND,
        silo1Type: SiloType.COMPOUND,
        silo0Address: '0x39aa39c021dfbae8fac545936693ac917d5e7563',
        silo1Address: '0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5',
    }],
    // Blend Vault V2: WETH / OHM
    ['0x8Bc7C34009965ccb8c0C2eB3d4db5a231eCc856C', {
        silo0Type: SiloType.STAKE_OHM,
        silo1Type: SiloType.RARI_FUSE,
        silo0Address: '0x04F2694C8fcee23e8Fd0dfEA1d4f5Bb8c352111F',
        silo1Address: '0xFA1057d02A0C1a4885851e3F4fD496Ee7D38F56e',
    }],
]);

export function GetSiloData(address: string) : SiloData | undefined {
    return SiloDataMap.get(address);
}
