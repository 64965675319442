import React from "react";
import styled from "styled-components/macro";
// import {useToast} from "@chakra-ui/react";
import {IoChevronBackSharp as SwapIcon} from "react-icons/all";
import {FeeTier, IsBlendVault, VaultData} from "../../constants/VaultData";
import {Link} from 'react-router-dom';

const Container = styled.div`
  padding: 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  color: white;
  font-size: 18pt;
  font-weight: bold;
  user-select: none;
  margin-bottom: 24px;

  // Neumorphic
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4),
    -8px -8px 16px rgba(73, 73, 73, 0.4);
  //background: linear-gradient(to top left, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.2));
  //background: rgba(255, 255, 255, 0.2);
  //
  //:active {
  //  border-radius: 16px;
  //  box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.4),
  //    inset -4px -4px 8px rgba(73, 73, 73, 0.4);
  //  background: none;
  //}
  //
  //:hover:not(:active) {
  //  background: linear-gradient(to top left, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.3));
  //}

  span {
    padding: 5px 10px;
  }
`

const FeeTierSeparator = styled.span`
  border-left: 1px solid #505b65;
  font-weight: normal;
`

export default function TokenSelector(props: {vaultData: VaultData}) {
    //
    // const toast = useToast();
    //
    // const OnClick = () => {
    //     // toast({
    //     //     title: "More vaults coming soon :)",
    //     //     status: 'info',
    //     //     duration: 9000,
    //     //     position: 'bottom',
    //     //     isClosable: true,
    //     // })
    // }

    return (
        // @ts-ignore
        <Link to={(IsBlendVault(props.vaultData.vaultType)) ? '/blendvaults' : '/activevaults'}>
            <Container>
                <SwapIcon color={'white'} fontSize={'18pt'} />
                <span>{props.vaultData.token0Ticker}</span>
                <span>—</span>
                <span>{props.vaultData.token1Ticker}</span>
                <FeeTierSeparator>{((feeTier: FeeTier) => {
                    switch (feeTier) {
                        case FeeTier.ZERO_ZERO_FIVE:
                            return <>0.05%</>
                        case FeeTier.ZERO_THREE:
                            return <>&nbsp;0.3%</>
                        case FeeTier.ONE:
                            return <>&nbsp;&nbsp;1%</>
                        case FeeTier.INVALID:
                            return <>INV</>
                        case FeeTier.UNKNOWN:
                        default:
                            return <>UNK</>
                    }
                })(props.vaultData.feeTier)}</FeeTierSeparator>
            </Container>
        </Link>
    );
}