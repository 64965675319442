import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import axios from "axios";
import {Table,} from "@chakra-ui/react"
import {useWeb3React} from "@web3-react/core";
import {Contract, ethers} from "ethers";
import ActiveVaultAbi from "../../../abis/pool_abi.json";
import BlendVaultAbi from "../../../abis/blend_pool_abi.json"
import {toFixed} from "../../../util/ToFixed";
import {IsBlendVault, VaultData, VaultType} from "../../../constants/VaultData";
import {String10E} from "../../../util/Decimals";

const Container = styled.div`
  margin-bottom: 160px; // Padding for the bottom edge of page
`

const TableContainer = styled.div<{lastItemBottomBorder: boolean}>`
  padding: 8px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
  font-size: 14pt;
  margin-top: 24px;

  thead {
    font-size: 18pt;
  }

  tr {
    padding: 4px;
    border-bottom: 1px solid #5b5b5b;
    :hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  tr:last-child {
    border-bottom: ${({ lastItemBottomBorder }) => (lastItemBottomBorder) ? 'border-bottom: 1px solid #465764;' : 'none'};
  }

  // Neumorphic
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4),
    -8px -8px 16px rgba(73, 73, 73, 0.4);
`

const Heading = styled.div`
  font-size: 18pt;
  font-weight: bold;
`

const TdRight = styled.td`
  white-space: pre-wrap;
  text-align: right;
`

const TickerLabel = styled.span`
  font-size: 14pt;
  font-weight: bold;
`

const HintFormatter = styled.span`
  font-size: 14pt;
  color: gray;
`


export default function VaultStatsTable(props: {vaultData: VaultData}) {

    const { active, account, library } = useWeb3React();

    const [TVL, setTVL] = useState('-');
    const [usdcReserves, setUsdcReserves] = useState('-');
    const [wethReserves, setWethReserves] = useState('-');
    const [depositCap, setDepositCap] = useState('-');
    const [depositCapUsed, setDepositCapUsed] = useState('-');

    const [token0Holdings, setToken0Holdings] = useState('-');
    const [token1Holdings, setToken1Holdings] = useState('-');
    const [holdingsValue, setHoldingsValue] = useState('-');

    useEffect(() => {
        const LoadTable = async() => {
            if (active) {
                try {
                    const signer = library.getSigner();
                    const poolContract = new Contract(props.vaultData.vaultAddress, (IsBlendVault(props.vaultData.vaultType)) ? BlendVaultAbi : ActiveVaultAbi).connect(signer);
                    let reserves0, reserves1;
                    if (IsBlendVault(props.vaultData.vaultType)) {
                        [reserves0, reserves1] = await poolContract.getInventory();
                    } else {
                        [reserves0, reserves1] = await poolContract.getReserves();
                    }

                    let holdingShares = await poolContract.balanceOf(account);

                    if (props.vaultData.vaultType === VaultType.BLEND_V2) {

                        const token0Amount = ethers.utils.formatUnits(reserves0, props.vaultData.token0Decimals);
                        const token1Amount = ethers.utils.formatUnits(reserves1, props.vaultData.token1Decimals);
                        setUsdcReserves(toFixed(token0Amount, 3));
                        setWethReserves(toFixed(token1Amount, 6));

                        const resp = await axios.get<any>(`https://api.etherscan.io/api?module=stats&action=ethprice&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`);
                        const ethUsd = ethers.utils.parseUnits(resp.data.result.ethusd, 6);

                        const tickTWAP = (await poolContract.getNextPositionWidth()).tickTWAP;
                        const TWAP = (1.0001 ** tickTWAP).toFixed(0);
                        const decimalDelta = props.vaultData.token1Decimals - props.vaultData.token0Decimals;
                        const token0Pertoken1 = (ethers.BigNumber.from(String10E(decimalDelta + 6)).div(TWAP));
                        // OHM per ETH, with 6 decimal place

                        const ohmPrice = ethUsd.mul(String10E(6)).div(token0Pertoken1);


                        const tvlNum = (reserves0.mul(ohmPrice).div(String10E(9))).add(reserves1.mul(ethUsd).div('0xDE0B6B3A7640000')); // hex is 10^18
                        setTVL(`$${toFixed((ethers.utils.formatUnits(tvlNum, 6)), 2)}`);

                        const shares: ethers.BigNumber = await poolContract.totalSupply();
                        const maxShares: ethers.BigNumber = await poolContract.maxTotalSupply();
                        // const depositCapNum = maxShares.mul(tvlNum.mul(BigNumberString(12))).div(shares)
                        // setDepositCap(`~$${toFixed((ethers.utils.formatUnits(depositCapNum, 18)), 2)}`);
                        setDepositCap(`-`);

                        const depositCapPercentage = shares.mul(10000).div(maxShares);
                        setDepositCapUsed(`${toFixed(ethers.utils.formatUnits(depositCapPercentage, 2), 2)}%`);

                        setToken0Holdings(toFixed(ethers.utils.formatUnits(holdingShares.mul(reserves0).div(shares), props.vaultData.token0Decimals), 3));
                        setToken1Holdings(toFixed(ethers.utils.formatUnits(holdingShares.mul(reserves1).div(shares), props.vaultData.token1Decimals), 3));

                        const holdingsValueNum = (reserves0.mul(ohmPrice).mul(holdingShares).div(shares).div(String10E(9))).add(reserves1.mul(ethUsd).mul(holdingShares).div(shares).div('0xDE0B6B3A7640000'));
                        setHoldingsValue(`$${toFixed(ethers.utils.formatUnits(holdingsValueNum, 6), 2)}`)

                    } else {

                        const token0Amount = ethers.utils.formatUnits(reserves0, props.vaultData.token0Decimals);
                        const token1Amount = ethers.utils.formatUnits(reserves1, props.vaultData.token1Decimals);
                        setUsdcReserves(toFixed(token0Amount, 2));
                        setWethReserves(toFixed(token1Amount, 6));

                        const resp = await axios.get<any>(`https://api.etherscan.io/api?module=stats&action=ethprice&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`);
                        const ethUsd = ethers.utils.parseUnits(resp.data.result.ethusd, 6);
                        const tvlNum = reserves0.add(reserves1.mul(ethUsd).div('0xDE0B6B3A7640000')); // hex is 10^18
                        setTVL(`$${toFixed((ethers.utils.formatUnits(tvlNum, 6)), 2)}`);

                        const shares: ethers.BigNumber = await poolContract.totalSupply();
                        const maxShares: ethers.BigNumber = await poolContract.maxTotalSupply();
                        const depositCapNum = maxShares.mul(tvlNum.mul(10 ** 12)).div(shares)
                        setDepositCap(`~$${toFixed((ethers.utils.formatUnits(depositCapNum, 18)), 2)}`);

                        const depositCapPercentage = shares.mul(10000).div(maxShares);
                        setDepositCapUsed(`${toFixed(ethers.utils.formatUnits(depositCapPercentage, 2), 4)}%`);

                        setToken0Holdings(toFixed(ethers.utils.formatUnits(holdingShares.mul(reserves0).div(shares), props.vaultData.token0Decimals), 3));
                        setToken1Holdings(toFixed(ethers.utils.formatUnits(holdingShares.mul(reserves1).div(shares), props.vaultData.token1Decimals), 3));

                        const holdingsValueNum = reserves0.mul(holdingShares).div(shares).add(reserves1.mul(holdingShares).div(shares).mul(ethUsd).div('0xDE0B6B3A7640000')); // hex is 10^18
                        setHoldingsValue(`$${toFixed((ethers.utils.formatUnits(holdingsValueNum, 6)), 2)}`);

                    }

                } catch (e) {
                    console.log(e);
                }
            }
        }
        LoadTable();
    }, [active, account, library, props.vaultData]);

    return (
        <Container>
            <TableContainer lastItemBottomBorder={false}>
                <Heading>
                    Vault&nbsp;Usage <HintFormatter>{(!active) ? '(Connect Web3 to View)' : ''}</HintFormatter>
                </Heading>
                <Table>
                    <tbody>
                    <tr key={1}>
                        <td key={1}>TVL</td>
                        <TdRight key={2}>{TVL}</TdRight>
                    </tr>
                    <tr key={2}>
                        <td key={1}><TickerLabel>{props.vaultData.token0Ticker}</TickerLabel>&nbsp;Reserves</td>
                        <TdRight key={2}>{usdcReserves}&nbsp;<TickerLabel>{props.vaultData.token0Ticker}</TickerLabel></TdRight>
                    </tr>
                    <tr key={3}>
                        <td key={1}><TickerLabel>{props.vaultData.token1Ticker}</TickerLabel>&nbsp;Reserves</td>
                        <TdRight key={2}>{wethReserves}&nbsp;<TickerLabel>{props.vaultData.token1Ticker}</TickerLabel></TdRight>
                    </tr>
                    <tr key={4}>
                        <td key={1}>Deposit&nbsp;Cap</td>
                        <TdRight key={2}>{depositCap}</TdRight>
                    </tr>
                    <tr key={5}>
                        <td key={1}>Deposit&nbsp;Cap&nbsp;Used</td>
                        <TdRight key={2}>{depositCapUsed}</TdRight>
                    </tr>
                    </tbody>
                </Table>
            </TableContainer>
            <TableContainer lastItemBottomBorder={false}>
                <Heading>
                    Your&nbsp;Position
                </Heading>
                <Table>
                    <tbody>
                    <tr key={1}>
                        <td key={1}>Value</td>
                        <TdRight key={2}>{holdingsValue}</TdRight>
                    </tr>
                    <tr key={2}>
                        <td key={1}><TickerLabel>{props.vaultData.token0Ticker}</TickerLabel>&nbsp;Holdings</td>
                        <TdRight key={2}>{token0Holdings}&nbsp;<TickerLabel>{props.vaultData.token0Ticker}</TickerLabel></TdRight>
                    </tr>
                    <tr key={3}>
                        <td key={1}><TickerLabel>{props.vaultData.token1Ticker}</TickerLabel>&nbsp;Holdings</td>
                        <TdRight key={2}>{token1Holdings}&nbsp;<TickerLabel>{props.vaultData.token1Ticker}</TickerLabel></TdRight>
                    </tr>
                    </tbody>
                </Table>
            </TableContainer>
        </Container>
    );

}