import {VaultData} from "../constants/VaultData";
import {Contract, ethers} from "ethers";
import CTokenAbi from "../abis/c_usdc_abi.json";
import {ResolvedSilo} from "./ResolvedSilo";
import {String10E} from "../util/Decimals";


export async function FuseResolver(vaultData: VaultData, siloAddress: string, isSilo1: boolean, signer: ethers.Signer, priceUsd: ethers.BigNumber): Promise<ResolvedSilo> {
    const cToken = new Contract(siloAddress, CTokenAbi).connect(signer);

    const uTokenBalance =
        (await cToken.balanceOf(vaultData.vaultAddress))
            .mul(await cToken.exchangeRateStored()).div(String10E(18)); // cTokens always 8 decimals!

    const uTokenValue = uTokenBalance.mul(priceUsd).div(String10E(((isSilo1) ? vaultData.token1Decimals : vaultData.token0Decimals))); // hex is 10^18
    // console.log(uTokenBalance.toString());
    // console.log(priceUsd.toString());
    // console.log(uTokenValue.toString());
    // console.log((await cToken.exchangeRateStored()).toString());

    return {
        balance: uTokenBalance,
        balanceUSD: uTokenValue,
    }
}