
/**
 * This file defines the information needed to connect to and display an Aloe Vault.
 * Currently, it also record all needed vault data locally (i.e. hardcoded into the frontend)
 * With permissionless vault creation, this data will need to be fetchable on-chain.
 */


export enum VaultType {
    UNKNOWN,
    INVALID,
    ACTIVE_V1,
    BLEND_V1,
    BLEND_V2,
}

export enum FeeTier {
    UNKNOWN,
    INVALID,
    ZERO_ZERO_FIVE,
    ZERO_THREE,
    ONE
}

export type VaultData = {
    vaultAddress: string,
    token0Address: string,
    token1Address: string,
    token0Ticker: string,
    token1Ticker: string,
    token0Decimals: number,
    token1Decimals: number,
    vaultType: VaultType,
    feeTier: FeeTier
}

const VaultDataMap = new Map<string, VaultData>([
    // Active Vault V1: USDC/WETH
    ['0xf5F30EaF55Fd9fFc70651b13b791410aAd663846', {
        vaultAddress: '0xf5F30EaF55Fd9fFc70651b13b791410aAd663846',
        token0Address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        token1Address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        token0Ticker: 'USDC',
        token1Ticker: 'WETH',
        token0Decimals: 6,
        token1Decimals: 18,
        vaultType: VaultType.ACTIVE_V1,
        feeTier: FeeTier.ZERO_ZERO_FIVE,
    }],
    // Blend Vault V1: USDC/WETH
    ['0x1cF3e6f18223a1f2A445f2cD60538Af380e98074', {
        vaultAddress: '0x1cF3e6f18223a1f2A445f2cD60538Af380e98074',
        token0Address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        token1Address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        token0Ticker: 'USDC',
        token1Ticker: 'WETH',
        token0Decimals: 6,
        token1Decimals: 18,
        vaultType: VaultType.BLEND_V1,
        feeTier: FeeTier.ZERO_ZERO_FIVE,
    }],
    // Blend Vault V2: WETH / OHM
    ['0x8Bc7C34009965ccb8c0C2eB3d4db5a231eCc856C', {
        vaultAddress: '0x8Bc7C34009965ccb8c0C2eB3d4db5a231eCc856C',
        token0Address: '0x383518188c0c6d7730d91b2c03a03c837814a899',
        token1Address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        token0Ticker: 'OHM',
        token1Ticker: 'WETH',
        token0Decimals: 9,
        token1Decimals: 18,
        vaultType: VaultType.BLEND_V2,
        feeTier: FeeTier.ONE,
    }],
]);

export function GetAllVaults(): Array<VaultData> {
    return Array.from(VaultDataMap.values());
}

export function GetVaultData(address: string) : VaultData | undefined {
    return VaultDataMap.get(address);
}

export function IsBlendVault(type: VaultType) {
    return type === VaultType.BLEND_V1 || type === VaultType.BLEND_V2;
}


