import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {useWeb3React} from "@web3-react/core";
import {ethers} from "ethers";
// import {USDC_WETH_BLEND_VAULT_ADDRESS} from "../../constants/Addresses";
import ActiveVaultAbi from "../../abis/pool_abi.json"
import {toFixed} from "../../util/ToFixed";
import BlendVaultAbi from "../../abis/blend_pool_abi.json";
import {IsBlendVault, VaultData} from "../../constants/VaultData";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
`

const Heading = styled.p`
  white-space: pre-wrap;
  text-align: left;
`

const TickerLabel = styled.div`
  //font-size: 18pt;
  font-weight: bold;
  padding: 6px 6px 6px 8px;
`

export default function WithdrawPreview(props: {shares?: string, vaultData: VaultData}) {

    const {active, library} = useWeb3React();
    const [returns0, setReturns0] = useState('');
    const [returns1, setReturns1] = useState('');

    useEffect(() => {
        if (props.shares) {
            try {
                const GetApproximateReturns = async (shares: ethers.BigNumber) => {
                    if (active) {
                        try {
                            const signer = library.getSigner();
                            let poolContract = new ethers.Contract(props.vaultData.vaultAddress, (IsBlendVault(props.vaultData.vaultType)) ? BlendVaultAbi : ActiveVaultAbi).connect(signer);
                            let reserves0, reserves1;
                            if (IsBlendVault(props.vaultData.vaultType)) {
                                [reserves0, reserves1] = await poolContract.getInventory();
                            } else {
                                [reserves0, reserves1] = await poolContract.getReserves();
                            }
                            const totalSupply = await poolContract.totalSupply();
                            const amount0 = reserves0.mul(shares).div(totalSupply);
                            const amount1 = reserves1.mul(shares).div(totalSupply);
                            setReturns0(toFixed(ethers.utils.formatUnits(amount0, props.vaultData.token0Decimals), 4));
                            setReturns1(toFixed(ethers.utils.formatUnits(amount1, props.vaultData.token1Decimals), 4));
                        } catch (e) {
                            console.log(e);
                            setReturns0('-');
                            setReturns1('-');
                        }
                    } else {
                        setReturns0('0');
                        setReturns1('0');
                    }
                }
                GetApproximateReturns(ethers.utils.parseUnits(props.shares, 18));
            } catch (e) {
                setReturns0('-');
                setReturns1('-');
            }
        } else {
            setReturns0('0');
            setReturns1('0');
        }
    }, [props.shares, active, library, props.vaultData]);

    return (
        <Container>
            <Heading>
                Will return approximately:
            </Heading>
            <Row>
                {active ? returns0 : '0'}
                <TickerLabel>
                    {props.vaultData.token0Ticker}
                </TickerLabel>
            </Row>
            <Row>
                {active ? returns1 : '0'}
                <TickerLabel>
                    {props.vaultData.token1Ticker}
                </TickerLabel>
            </Row>
        </Container>
    );
}