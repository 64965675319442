import React, {Suspense} from 'react';
import {Redirect, Route, RouteComponentProps, Switch} from 'react-router-dom'
import {ChakraProvider} from "@chakra-ui/react";


import styled from 'styled-components/macro'

import Header from '../components/Header'
import Footer from "../components/Footer";
import ActiveVault from "./ActiveVault";
// import Faucet from "./faucet";
import Vote from "./Vote";
// import Claim from "./Claim";
import BlendVault from "./BlendVault";
import {GetVaultData, IsBlendVault, VaultType} from "../constants/VaultData";
import UnrecognizedVault from "./UnrecognizedVault";
import VaultSelect from "./VaultSelect";


function RedirectPathOnEntry({ location }: RouteComponentProps) {
  return <Redirect to={{ ...location, pathname: '/blendvaults' }} />
}

const AppWrapper = styled.div`
  min-height: 100vh;
  max-width: 100%;
  margin: 0;

  display: grid;
  grid-template-rows: auto 1fr auto;
  background: #11222E;
`

interface MatchParams {
    vaultAddress: string;
}

const VaultMapper = (props: RouteComponentProps<MatchParams>) => {
    const vaultData = GetVaultData(props.match.params.vaultAddress);
    if (vaultData) {
        switch (vaultData.vaultType) {
            case VaultType.ACTIVE_V1:
                return <ActiveVault vaultData={vaultData} />
            case VaultType.BLEND_V1:
            case VaultType.BLEND_V2:
                return <BlendVault vaultData={vaultData} />
            default:
                return <UnrecognizedVault />
        }
    } else {
        return <UnrecognizedVault />
    }
}

function App() {
    return (
        <Suspense fallback={null}>
            <ChakraProvider>
                <AppWrapper>
                    <Header />
                    <Switch>
                        {/*<Route exact strict path="/vaultselect" component={VaultSelect} />*/}
                        {/*<Route exact strict path="/stake" component={Stake} />*/}
                        {/*TEMP! Only one active vault, so don't draw the switcher. // <Route exact strict path="/activevaults" render={() => <VaultSelect vaultTypeFilter={VaultType.ACTIVE_V1} />} />*/}
                        <Route exact strict path="/activevaults" component={({ location }: RouteComponentProps) => <Redirect to={{ ...location, pathname: '/activevaults/0xf5F30EaF55Fd9fFc70651b13b791410aAd663846' }} />} />
                        <Route exact strict path="/blendvaults" render={() => <VaultSelect vaultTypeFilter={IsBlendVault} />} />
                        <Route path="/activevaults/:vaultAddress" component={VaultMapper} />
                        <Route path="/blendvaults/:vaultAddress" component={VaultMapper} />
                        {/*<Route exact strict path="/claim" component={Claim} />*/}
                        <Route exact strict path="/vote" component={Vote} />
                        <Route component={RedirectPathOnEntry} />
                    </Switch>
                    <Footer />
                </AppWrapper>
            </ChakraProvider>
        </Suspense>
  );
}

export default App;
