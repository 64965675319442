import React from "react";
import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react"
import styled from "styled-components/macro";
import DepositTab from "./DepositTab";
import WithdrawTab from "./WithdrawTab"
import {VaultData, VaultType} from "../../constants/VaultData";
// import {USDC_ADDRESS, WETH_ADDRESS} from "../../constants/Addresses";


const Container = styled.div`
  color: white;
  width: 100%;
  //border: 1px solid #465764;
  padding-top: 0;
  overflow: hidden;

  // Neumorphic
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4),
    -8px -8px 16px rgba(73, 73, 73, 0.4);
`

const TitleFormatter = styled.div`
  font-weight: bold;
  font-size: 24pt;
  //border-bottom: 1px solid #465764;
  margin: 0;
`

const BOX_SHADOW_NEUMORPHIC_OUTER = '8px 8px 16px rgba(0, 0, 0, 0.2), -8px -8px 16px rgba(255, 255, 255, 0.2)';
const BG_GREEN_GRADIENT = "radial-gradient(63.91% 190.03% at 95.41% 156.91%, rgba(20, 255, 0, 0.5) 0%, rgba(17, 255, 12, 0) 100%), #119553;";
const BG_ORANGE_GRADIENT = "radial-gradient(79.61% 235.8% at 93.5% 77.66%, rgba(255, 184, 0, 0.5) 0%, rgba(215, 166, 39, 0) 89.78%), #C6300A;";
// #2f3135 is same as global background color; linear-gradient is same as TokenSelector in normal state
const BUTTON_BACKGROUND = '#2f3135';

const DepositButtonStyle = {
    "fontFamily": 'Inter',
    "fontSize": '18pt',
    margin: '16px 8px 8px 16px',
    padding: '8px',
    // Neumorphic
    background: BUTTON_BACKGROUND,
    'borderRadius': '16px',
    'boxShadow': 'inset 8px 8px 16px rgba(0, 0, 0, 0.4), inset -8px -8px 16px rgba(73, 73, 73, 0.4)',
};

const WithdrawButtonStyle = {
    "fontFamily": 'Inter',
    "fontSize": '18pt',
    margin: '16px 16px 8px 8px',
    padding: '8px',
    // Neumorphic
    background: BUTTON_BACKGROUND,
    'borderRadius': '16px',
    'boxShadow': 'inset 8px 8px 16px rgba(0, 0, 0, 0.4), inset -8px -8px 16px rgba(73, 73, 73, 0.4)',
};

const TabsBg = styled.div<{ tabIndex: number }>`
  background: ${({ tabIndex }) => {
      return (tabIndex === 0)
              ? BG_GREEN_GRADIENT
              : BG_ORANGE_GRADIENT;
  }};
`;

export default function VaultInteractionTabs(props: {vaultData: VaultData, disabledDeposits?: boolean}) {

    const isActiveVault = props.vaultData.vaultType === VaultType.ACTIVE_V1;

    const [tabIndex, setTabIndex] = React.useState((isActiveVault) ? 1 : 0);

    return (
        <Container>
            <TabsBg tabIndex={tabIndex}>
                <Tabs onChange={(index) => setTabIndex(index)} isFitted={true} variant="unstyled" size={"lg"} defaultIndex={(isActiveVault) ? 1 : 0}>
                    <TitleFormatter>
                        <TabList>
                            <Tab sx={DepositButtonStyle} _focus={{outline: "none"}} _selected={{
                                ...DepositButtonStyle,
                                background: 'none',
                                'boxShadow': BOX_SHADOW_NEUMORPHIC_OUTER,
                            }}>Deposit</Tab>
                            <Tab sx={WithdrawButtonStyle} _focus={{outline: "none"}} _selected={{
                                ...WithdrawButtonStyle,
                                background: 'none',
                                'boxShadow': BOX_SHADOW_NEUMORPHIC_OUTER,
                            }}>Withdraw</Tab>
                        </TabList>
                    </TitleFormatter>
                    <TabPanels>
                        <TabPanel>
                            <DepositTab vaultData={props.vaultData} disabled={props.disabledDeposits} />
                        </TabPanel>
                        <TabPanel>
                            <WithdrawTab vaultData={props.vaultData} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </TabsBg>
        </Container>
    );
}