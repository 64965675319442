import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {NumberInput, NumberInputField, useToast} from "@chakra-ui/react";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from "@chakra-ui/react"
import {useWeb3React} from "@web3-react/core";
import {ethers, Contract, BigNumber} from "ethers";
import ActiveVaultAbi from "../../abis/pool_abi.json"
import BlendVaultAbi from "../../abis/blend_pool_abi.json"
import Erc20Abi from "../../abis/erc20_abi.json"

import {WETH_ADDRESS} from "../../constants/Addresses";
import erc20ABI from "../../abis/erc20_abi.json";
import {IsBlendVault, VaultData} from "../../constants/VaultData";

enum ButtonState {
    DEPOSIT,
    APPROVE_0,
    APPROVE_1,
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
`

const TickerLabel = styled.div`
  font-size: 18pt;
  font-weight: bold;
  padding: 6px 6px 6px 8px;
`

const NumberInputSx = {
    border: 'none',
    fontSize: '16pt',
    textAlign: 'right',
    _focus: {outline: 'none'},
    background: '#2f3135',
    borderRadius: '50px',
    padding: '8px',
}

const ApproveButton = styled.button`
  background: linear-gradient(to top left, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(119.44% 421.84% at 13.11% 38.51%, rgba(74, 207, 103, 0.5) 0%, rgba(79, 194, 169, 0) 100%), #52B3E3;
  margin: 0 40px;
  width: 100%;
  padding: 8px 50px;
  font-size: 18pt;
  white-space: nowrap;
  border: 0;
  font-weight: bold;
  :focus {
    outline: none;
  }

  // Neumorphic
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2),
    -8px -8px 16px rgba(255, 255, 255, 0.2);

  :active {
    border-radius: 16px;
    box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.1),
      inset -4px -4px 8px rgba(255, 255, 255, 0.2);
    background: radial-gradient(119.44% 421.84% at 13.11% 38.51%, rgba(74, 207, 103, 0.5) 0%, rgba(79, 194, 169, 0) 100%), #52B3E3;
  }

  :hover:not(:active) {
    background: linear-gradient(to top left, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.3)), radial-gradient(119.44% 421.84% at 13.11% 38.51%, rgba(74, 207, 103, 0.5) 0%, rgba(79, 194, 169, 0) 100%), #52B3E3;
  }
`

const DepositButton = styled.button`
  margin: 0 40px;
  width: 100%;
  padding: 8px 50px;
  font-size: 18pt;
  white-space: nowrap;
  font-weight: bold;

  // Neumorphic
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2),
    -8px -8px 16px rgba(255, 255, 255, 0.2);
  background: linear-gradient(to top left, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.2));

  :active {
    border-radius: 16px;
    box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.1),
      inset -4px -4px 8px rgba(255, 255, 255, 0.2);
    background: none;
  }

  :hover:not(:active) {
    background: linear-gradient(to top left, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.3));
  }
`

const ToggleSx = {
    _focus: {outline: 'none'},
}

const AccordionSx = {
    border: '0',
}

const formatPercent = (input: string) => {
    return `${input}%`;
}

const TextWrapper = styled.div`
  white-space: pre-wrap;
`

const MaxButton = styled.button`
  color: white;
  border: none;
  text-decoration: underline;
  font-size: 12pt;
  padding: 4px;
`

const Heading = styled.p`
  white-space: pre-wrap;
  text-align: left;
`

const parsePercent = (val: string): string => val.replace(/^%/, "");

export default function DepositTab(props: {vaultData: VaultData, disabled?: boolean}) {

    const { active, account, library } = useWeb3React();
    const toast = useToast();

    const [token0Amount, setToken0Amount] = useState('');
    const [token1Amount, setToken1Amount] = useState('');
    const [depositRatioChange, setDepositRatioChange] = useState('1.0');
    const [buttonState, setButtonState] = useState(ButtonState.DEPOSIT);

    // const GetBalance = async (address: string) => {
    //     try {
    //
    //     } catch (e) {
    //         console.log(e);
    //         return ethers.BigNumber.from(0);
    //     }
    // }

    const adjustToken1Amount = async (token0: string) => {
        if (active) {
            try {
                const signer = library.getSigner();
                const poolContract = new Contract(props.vaultData.vaultAddress, (IsBlendVault(props.vaultData.vaultType)) ? BlendVaultAbi : ActiveVaultAbi).connect(signer);

                if ((await poolContract.totalSupply()).eq(0)) {
                    return;
                }

                let reserves0, reserves1;
                if (IsBlendVault(props.vaultData.vaultType)) {
                    [reserves0, reserves1] = await poolContract.getInventory();
                } else {
                    [reserves0, reserves1] = await poolContract.getReserves();
                }
                // //console.log(`USDC Holdings: ${}`)
                // console.log(reserves0);
                // console.log(reserves1);
                const target = ethers.utils.parseUnits(token0, props.vaultData.token0Decimals).mul(reserves1).div(reserves0);
                setToken1Amount(ethers.utils.formatUnits(target, props.vaultData.token1Decimals));
            } catch (e: any) {
                console.log(e);
            }
        }
    }

    const adjustToken0Amount = async (token1: string) => {
        if (active) {
            try {
                const signer = library.getSigner();
                const poolContract = new Contract(props.vaultData.vaultAddress, (IsBlendVault(props.vaultData.vaultType)) ? BlendVaultAbi : ActiveVaultAbi).connect(signer);

                if ((await poolContract.totalSupply()).eq(0)) {
                    return;
                }

                let reserves0, reserves1;
                if (IsBlendVault(props.vaultData.vaultType)) {
                    [reserves0, reserves1] = await poolContract.getInventory();
                } else {
                    [reserves0, reserves1] = await poolContract.getReserves();
                }
                const target = ethers.utils.parseUnits(token1, props.vaultData.token1Decimals).mul(reserves0).div(reserves1);
                setToken0Amount(ethers.utils.formatUnits(target, props.vaultData.token0Decimals));
            } catch (e: any) {
                console.log(e);
            }
        }
    }

    const GetAllowance = async (token: Contract) => {
        return await token.allowance(account, props.vaultData.vaultAddress);
    }

    // const SetMax = async () => {
    //     if (active) {
    //         const signer = library.getSigner();
    //         const usdcContract = new Contract(USDC_ADDRESS, erc20ABI).connect(signer);
    //         const balance: string = await usdcContract.balanceOf(account);
    //         const newToken0 = ethers.utils.formatUnits(balance, 6);
    //         setToken0Amount(newToken0);
    //         adjustToken1Amount(newToken0);
    //     }
    // }

    const SetMax0 = async () => {
        if (active) {
            const signer = library.getSigner();
            const token0Contract = new Contract(props.vaultData.token0Address, erc20ABI).connect(signer);
            const balance: string = await token0Contract.balanceOf(account);
            const newToken0 = ethers.utils.formatUnits(balance, props.vaultData.token0Decimals);
            setToken0Amount(newToken0);
            adjustToken1Amount(newToken0);
        }
    }

    const SetMax1 = async () => {
        if (active) {
            const signer = library.getSigner();
            const wethContract = new Contract(WETH_ADDRESS, erc20ABI).connect(signer);
            const balance: string = await wethContract.balanceOf(account);
            const newToken1 = ethers.utils.formatUnits(balance, 18);
            setToken1Amount(newToken1);
            adjustToken0Amount(newToken1);
        }
    }

   const Approve = async (token: Contract) => {
        await token.approve(props.vaultData.vaultAddress, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').then((res: ethers.ContractTransaction) => {
            res.wait().then(() => {
                setButtonState(ButtonState.DEPOSIT)
            }).catch((e) => {
                console.log(e);
            });
        });
   }

   const Approve0 = () => {
       const signer = library.getSigner();
       const token0Contract = new Contract(props.vaultData.token0Address, Erc20Abi).connect(signer);
       Approve(token0Contract);
   }

   const Approve1 = () => {
       const signer = library.getSigner();
       const token1Contract = new Contract(props.vaultData.token1Address, Erc20Abi).connect(signer);
       Approve(token1Contract);
   }

    const Deposit = (pool: Contract, amount0: ethers.BigNumber, amount1: ethers.BigNumber, amount0min: ethers.BigNumber, amount1min: ethers.BigNumber) => {
        pool.deposit(amount0, amount1, amount0min, amount1min).then((res: ethers.ContractTransaction) => {
                res.wait(0).then(() => {
                    setToken0Amount('');
                    setToken1Amount('');
                }).catch((err: any) => {
                    console.log(err)
                });
        }).catch((err: any) => {
            console.log(err)
            toast({
                title: "Error in making deposit",
                status: "error",
                duration: 9000,
                position: 'bottom',
                isClosable: true,
            })
        });
    };

    const SubmitDeposit = () => {
        if (!active) {
            toast({
                title: "No connected wallet",
                status: "error",
                duration: 9000,
                position: 'bottom',
                isClosable: true,
            })
            return;
        }
        try {
            const signer = library.getSigner();
            const poolContract = new Contract(props.vaultData.vaultAddress, (IsBlendVault(props.vaultData.vaultType)) ? BlendVaultAbi : ActiveVaultAbi).connect(signer);
            const token0min = Math.trunc(Number(token0Amount) * ((100.0 - Number(depositRatioChange)) / 100.0));
            const token1min = Math.trunc(Number(token1Amount) * ((100.0 - Number(depositRatioChange)) / 100.0));


            Deposit(poolContract, ethers.utils.parseUnits(token0Amount, props.vaultData.token0Decimals), ethers.utils.parseUnits(token1Amount, props.vaultData.token1Decimals),
                ethers.utils.parseUnits(String(token0min), props.vaultData.token0Decimals), ethers.utils.parseUnits(String(token1min), props.vaultData.token1Decimals));
        } catch (e) {
            console.log(e);
            toast({
                title: "Error in making deposit",
                status: "error",
                duration: 9000,
                position: 'bottom',
                isClosable: true,
            })
        }
    }

    let buttonText = "Confirm"
    let buttonAction = SubmitDeposit;

    if (buttonState === ButtonState.APPROVE_0) {
        buttonAction = Approve0;
        buttonText = `Approve ${props.vaultData.token0Ticker}`
    } else if (buttonState === ButtonState.APPROVE_1) {
        buttonAction = Approve1;
        buttonText = `Approve ${props.vaultData.token1Ticker}`
    }


    const updateButton = async () => {
        if (active) {
            try {
                const signer = library.getSigner();
                const token0Contract = new Contract(props.vaultData.token0Address, Erc20Abi).connect(signer);
                const token1Contract = new Contract(props.vaultData.token1Address, Erc20Abi).connect(signer);

                const token0BN = (String(Number(token0Amount)) === token0Amount) ? ethers.utils.parseUnits(token0Amount, props.vaultData.token0Decimals) : BigNumber.from(0);
                const token1BN = (String(Number(token1Amount)) === token1Amount) ? ethers.utils.parseUnits(token1Amount, props.vaultData.token1Decimals) : BigNumber.from(0);
                const allowance0: ethers.BigNumber = await GetAllowance(token0Contract);
                const allowance1: ethers.BigNumber = await GetAllowance(token1Contract);
                if (allowance0.lt(token0BN)) {
                    setButtonState(ButtonState.APPROVE_0);
                } else if (allowance1.lt(token1BN)) {
                    setButtonState(ButtonState.APPROVE_1);
                } else {
                    setButtonState(ButtonState.DEPOSIT);
                }
            } catch (e) {
                console.log(e);
                setButtonState(ButtonState.DEPOSIT);
                if (e.code === "NUMERIC_FAULT") {
                    toast({
                        title: "Invalid number input",
                        status: "error",
                        duration: 9000,
                        position: 'bottom',
                        isClosable: true,
                    })
                }
            }
        } else {
            setButtonState(ButtonState.DEPOSIT);
        }
    }

    useEffect(() => {
        updateButton();
    })


    return (
        <Container>
            {!props.disabled && (<>
                <Row>
                <MaxButton onClick={SetMax0}>
                    max
                </MaxButton>
                <NumberInput
                    onChange={(value) => {
                        setToken0Amount(value);
                        adjustToken1Amount(value);
                    }}
                    value={token0Amount}
                    min={0}
                >
                    <NumberInputField placeholder="0.0" sx={NumberInputSx}/>
                </NumberInput>
                <TickerLabel>
                    {props.vaultData.token0Ticker}
                </TickerLabel>
            </Row>
            <Row>
                <MaxButton onClick={SetMax1}>
                    max
                </MaxButton>
                <NumberInput
                    onChange={(value) => {
                        setToken1Amount(value)
                        adjustToken0Amount(value);
                    }}
                    value={token1Amount}
                    min={0}
                >
                    <NumberInputField placeholder="0.0" sx={NumberInputSx}/>
                </NumberInput>
                <TickerLabel>
                    {props.vaultData.token1Ticker}
                </TickerLabel>
            </Row>
            <Row>
                <Accordion allowToggle>
                    <AccordionItem sx={AccordionSx}>
                        <AccordionButton sx={ToggleSx}>
                            <AccordionIcon />
                            <Box flex="1" textAlign="center">
                                Maximum Deposit Ratio Change
                            </Box>
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <NumberInput
                                onChange={(value) => setDepositRatioChange(parsePercent(value))}
                                value={formatPercent(depositRatioChange)}
                                min={0}
                            >
                                <NumberInputField placeholder="0.0" sx={NumberInputSx}/>
                            </NumberInput>
                            <TextWrapper>
                                {'Because of price movements, your required deposit ratio might change.\n' +
                                'Transaction reverts if the total change is greater than this limit for either token.'}
                            </TextWrapper>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Row>
            <Row>
                {/*Split button style based on action*/}
                {(buttonState === ButtonState.DEPOSIT) ? (<DepositButton onClick={buttonAction}>
                    {buttonText}
                </DepositButton>) : (
                    <ApproveButton onClick={buttonAction}>
                        {buttonText}
                    </ApproveButton>
                )}

            </Row>
                </>)}
            {props.disabled && (<>
              <Row>
                  <Heading>Deposits into this vault are disabled.</Heading>
              </Row>
            </>)}
        </Container>
    );

}