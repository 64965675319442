import styled from "styled-components/macro";

export const SplitColumnHolder = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

export const SplitColumn = styled.div`
  display: grid;
  grid-template-columns: 10fr 7fr;
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  min-width: 600px;
  max-width: 1080px;
  margin-right: 30px;
  margin-left: 30px;
`

export const SingleColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  min-width: 600px;
  max-width: 1080px;
  margin-right: 30px;
  margin-left: 30px;
`

export const ColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  flex-wrap: nowrap;
  
  //:last-child {
  //  margin: 0 0 100px 0; // Padding for the bottom edge of page
  //}
  
`