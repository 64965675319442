import React from 'react';
import {FeeTier, IsBlendVault, VaultData} from "../../constants/VaultData";
import styled from "styled-components/macro";
import {Link} from "react-router-dom";
import {GetSiloData, SiloType} from "../../constants/SiloData";
import SiloLogoCompound from "../../assets/png/silo/compound.png"
import SiloLogoRariFuse from "../../assets/png/silo/rari_fuse.png"
import SiloLogoStakeOhm from "../../assets/png/silo/stake_ohm.png"
import {HexToRGBA} from "../../util/HexToRGBA"
import {TickerToColor} from "../common/TickerToColor";


const Wrapper = styled.div<{ color1: string, color2: string, }>`
  //border: 2px white solid;
  
  background: ${({ color1, color2 }) => `radial-gradient(117.96% 123.17% at -2.06% 114.88%, ${HexToRGBA(color1, '0.5')} 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(134.65% 134.65% at 100% -4.56%, ${HexToRGBA(color2, '0.5')} 0%, rgba(0, 0, 0, 0) 100%), rgba(8, 8, 14, 0.5);`}
  padding: 14px;
  border-radius: 24px;
  width: 400px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4),
    -8px -8px 16px rgba(73, 73, 73, 0.4);

  :hover:not(:active) {
    background: ${({ color1, color2 }) => `radial-gradient(117.96% 123.17% at -2.06% 114.88%, ${HexToRGBA(color1, '0.5')} 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(134.65% 134.65% at 100% -4.56%, ${HexToRGBA(color2, '0.5')} 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(to top left, rgba(0, 0, 0, 0.3), rgba(137, 137, 137, 0.15)), rgba(8, 8, 14, 0.5);`};
    //background:  rgba(8, 8, 14, 0.5);
  }
`

// const Layout = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// `
const Label = styled.div`
  color: white;
  font-size: 14pt;
`

export const Heading = styled.h1`
  display: block;
  font-size: 22pt;
  font-weight: bold;
  color: white;
  padding: 4px;
  text-align: left;
`

export const Body = styled.div`
  display: block;
  font-size: 13pt;
  text-wrap: normal;
  width: 100%;
  color: white;
  letter-spacing: unset;
  padding: 4px;
  white-space: pre-wrap;
`

export const InfoBoxContainer = styled.div`
  padding: 6px 10px;
  // margin: 0px 0px 100px 0px; // Padding between info box and bottom edge of page
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  ul {
    margin-left: 24px;
  }

  h2 {
    font-size: 16pt;
    font-weight: bold;
  }

  // Neumorphic
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4),
    -8px -8px 16px rgba(73, 73, 73, 0.4);
`

const CardFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3fr 7fr;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
`

const SilosLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin: 8px;
`

const SiloFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 3fr 2fr ;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  
  //background-color: #282c34;
  border: 1px solid gray;
  border-radius: 16px;
  padding: 8px;
`

// const SiloImg = styled.div`
//
// `

const SelfCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SiloDescLabel = styled.div`
  color: #cbcbcb;
  font-size: 10pt;
`
const SiloNameLabel = styled.div`
  color: #d5d5d5;
  font-size: 14pt;
`

export default function VaultCard (props: {vaultData: VaultData}) {

    const GetSiloImg = (siloType: SiloType) => {
        switch (siloType) {
            case SiloType.COMPOUND:
                return SiloLogoCompound;
            case SiloType.RARI_FUSE:
                return SiloLogoRariFuse;
            case SiloType.STAKE_OHM:
                return SiloLogoStakeOhm;
            default:
                return SiloLogoCompound;
        }
    }

    const SiloBuilder = (ticker: string, siloType: SiloType) => {
       return (
           <SiloFrame>
               <SiloDescLabel>{ticker}&nbsp;Silo</SiloDescLabel>
               <SelfCenter>
                   <img src={GetSiloImg(siloType)} width={'56px'} alt='' />
               </SelfCenter>
               <SelfCenter>
                   <SiloNameLabel>{((siloType: SiloType) => {
                       switch (siloType) {
                           case SiloType.COMPOUND:
                               return <>Compound</>;
                           case SiloType.RARI_FUSE:
                               return <>Rari&nbsp;Fuse</>;
                           case SiloType.STAKE_OHM:
                               return <>Stake&nbsp;OHM</>;
                           default:
                               return <>UNK</>
                       }})(siloType)}</SiloNameLabel>
               </SelfCenter>
        </SiloFrame>
       )
    };

    const siloData = GetSiloData(props.vaultData.vaultAddress);

    return <Link to={(IsBlendVault(props.vaultData.vaultType)) ? `/blendvaults/${props.vaultData.vaultAddress}` : `/activevaults/${props.vaultData.vaultAddress}`}>
        <Wrapper color1={TickerToColor(props.vaultData.token0Ticker)} color2={TickerToColor(props.vaultData.token1Ticker)}>
            <CardFrame>
                <Label>Aloe Blend</Label>
                <Heading>{props.vaultData.token0Ticker}&nbsp;—&nbsp;{props.vaultData.token1Ticker}&nbsp;{((feeTier: FeeTier) => {
                    switch (feeTier) {
                        case FeeTier.ZERO_ZERO_FIVE:
                            return <>0.05%</>
                        case FeeTier.ZERO_THREE:
                            return <>&nbsp;0.3%</>
                        case FeeTier.ONE:
                            return <>&nbsp;&nbsp;1%</>
                        case FeeTier.INVALID:
                            return <>INV</>
                        case FeeTier.UNKNOWN:
                        default:
                            return <>UNK</>
                    }})(props.vaultData.feeTier)}</Heading>
                <SilosLayout>
                    {(siloData) && SiloBuilder(props.vaultData.token0Ticker, siloData.silo0Type)}
                    {(siloData) && SiloBuilder(props.vaultData.token1Ticker, siloData.silo1Type)}
                </SilosLayout>
            </CardFrame>
        </Wrapper>
    </Link>

}