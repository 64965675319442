import React from 'react'
import {SplitColumnHolder} from "../components/common/SplitColumn";
import InfoBox from "../components/common/InfoBox";
import styled from "styled-components/macro";

const Limiter = styled.div`
  min-width: 600px;
  max-width: 600px;
  margin-right: 30px;
  margin-left: 30px;
`

export default function Vote() {
    return (
        <SplitColumnHolder>
            <Limiter>
                <InfoBox heading={'Aloe DAO'}>
                    <p>The Aloe DAO will control the Aloe Protocol and manage the treasury, which will be derived from a portion of vaults' swap fees. Governance will be done through Snapshot and should launch shortly.</p>
                </InfoBox>
            </Limiter>
        </SplitColumnHolder>
    );
}
