import styled from "styled-components/macro";

export const Heading = styled.h1`
  display: block;
  font-size: 22pt;
  font-weight: bold;
  color: white;
  padding-top: 4px;
  padding-bottom: 16px;
  padding-left: 4px;
  text-align: left;
`

export const Body = styled.div`
  display: block;
  font-size: 13pt;
  text-wrap: normal;
  width: 100%;
  color: white;
  letter-spacing: unset;
  padding: 4px;
  white-space: pre-wrap;
`

export const InfoBoxContainer = styled.div`
  padding: 6px 10px;
  // margin: 0px 0px 100px 0px; // Padding between info box and bottom edge of page
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  ul {
    margin-left: 24px;
  }

  h2 {
    font-size: 16pt;
    font-weight: bold;
  }

  // Neumorphic
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4),
    -8px -8px 16px rgba(73, 73, 73, 0.4);
`

export default function InfoBox(props: {heading: string, body?: string, children?: JSX.Element | JSX.Element[]}) {
    return (<InfoBoxContainer>
        <Heading>
            {props.heading}
        </Heading>
        <Body>
            {props.children}
        </Body>
    </InfoBoxContainer>)
}